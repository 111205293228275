import { CheckStatus } from "../status"

const HandleApi = async (pathApi,setVariable1) => {
    try{
        const res = await pathApi 
        setVariable1(res.data)
    }
    catch(err){
        const statusCode = err.response.data.code
        const messageCode = err.response.data.message
        // CheckStatus(statusCode, messageCode)
    }
}
export default HandleApi