import React, { useState, useEffect } from "react";
import { Col, Row, Card, Select } from "antd";
import { Button, Form, Input } from "antd";

import { apiv1 } from "../../../../core/data";
import { useDataAll } from "../../../../hooks";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

const AddProduct = () => {
  const [dataAll, dispatchDataAll] = useDataAll();
  const [categoryData, setCategoryData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  //need optimize
  const [productIdInput, setProductIdInput] = useState("");
  const [productNameInput, setProductNameInput] = useState("");
  const [productUnitInput, setProductUnitInput] = useState("");
  const [productBrandInput, setProductBrandInput] = useState(null);
  const [productSupplierInput, setProductSupplierInput] = useState(null);
  const [productCategoryInput, setProductCategoryInput] = useState(null);
  const [productPriceInput, setProductPriceInput] = useState(0);
  const [productSellPriceInput, setProductSellPriceInput] = useState(0);
  const [productWholeSalePriceInput, setProductWholeSalePriceInput] =
    useState(0);
  const [productDescriptionInput, setDescriptionInput] = useState("");
  const [productNoteInput, setProductNoteInput] = useState("");
  const [productSalePrice, setProductSalePrice] = useState("");
  const [productImg, setProductImg] = useState("");
  const handleSubmit = async () => {
    const productData = {
      product_id: productIdInput,
      product_name: productNameInput,
      product_unit: productUnitInput,
      product_brand: productBrandInput,
      product_supplier: productSupplierInput,
      product_category: productCategoryInput,
      product_price: productPriceInput,
      product_sell_price: productSellPriceInput,
      product_wholesale_price: productWholeSalePriceInput,
      product_description: productDescriptionInput,
      product_note: productNoteInput,
      product_img: productImg,
      product_sale_price: productSalePrice,
    };

    await apiv1.post("/products", productData, dataAll.keyHeader);

    window.location.reload(false);
  };

  useEffect(() => {
    const getSupplier = async () => {
      try {
        const res = await apiv1.get("/suppliers", dataAll.keyHeader);
        console.log(res.data.metadata.suppliers);
        setSupplierData(res.data.metadata.suppliers);
      } catch (error) {
        console.log(error.message);
      }
    };
    getSupplier();
  }, []);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const res = await apiv1.get("/categories", dataAll.keyHeader);
        console.log(res.data.metadata.categories);
        setCategoryData(res.data.metadata.categories);
      } catch (error) {
        console.log(error.message);
      }
    };

    getCategories();
  }, []);

  useEffect(() => {
    const getBrands = async () => {
      try {
        const res = await apiv1.get("/brands", dataAll.keyHeader);
        console.log(res.data.metadata.brands);
        setBrandData(res.data.metadata.brands);
      } catch (error) {
        console.log(error.message);
      }
    };

    getBrands();
  }, []);

  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <Form layout="vertical" onSubmitCapture={handleSubmit}>
          <Row gutter={10}>
            <Col span={12}>
              <Card>
                <Form.Item label="Mã sản phẩm" required>
                  <Input
                    placeholder="Mã sản phẩm"
                    name="productID"
                    value={productIdInput}
                    onChange={(e) => {
                      setProductIdInput(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Tên sản phẩm" required>
                  <Input
                    placeholder="Tên sản phẩm"
                    name="product"
                    value={productNameInput}
                    onChange={(e) => {
                      setProductNameInput(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Đơn vị tính">
                  <Input
                    placeholder="Đơn vị tính"
                    name="unit"
                    value={productUnitInput}
                    onChange={(e) => {
                      setProductUnitInput(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Giá nhập (VND)">
                  <Input
                    placeholder="Giá nhập"
                    name="price"
                    value={productPriceInput}
                    onChange={(e) => {
                      setProductPriceInput(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Giá bán (VND)">
                  <Input
                    placeholder="Giá bán"
                    name="sellPrice"
                    value={productSellPriceInput}
                    onChange={(e) => {
                      setProductSellPriceInput(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Giá sỉ (VND)">
                  <Input
                    placeholder="Giá sỉ"
                    name="wholeSalePrice"
                    onChange={(e) => {
                      setProductWholeSalePriceInput(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Giá Sale">
                  <Input
                    placeholder="Giá Sale"
                    value={productSalePrice}
                    name="SalePrice"
                    onChange={(e) => setProductSalePrice(e.target.value)}
                  />
                </Form.Item>
                <Form.Item label="Thương hiệu">
                  <Select
                    placeholder="Danh sách thương hiệu"
                    name="brand"
                    value={productBrandInput}
                    onChange={(value) => {
                      setProductBrandInput(value);
                    }}
                  >
                    {brandData.map((item) => {
                      return (
                        <>
                          <Option key={item._id} value={item._id}>
                            {item.brand_name}
                          </Option>
                        </>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Card>
            </Col>

            <Col span={12}>
              <Card>
                <Form.Item label="Danh mục">
                  <Select
                    placeholder="Danh sách danh mục"
                    name="category"
                    value={productCategoryInput}
                    onChange={(value) => {
                      setProductCategoryInput(value);
                    }}
                  >
                    {categoryData.map((item) => {
                      return (
                        <>
                          <Option key={item._id} value={item._id}>
                            {item.category_name}
                          </Option>
                        </>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Nhà cung cấp">
                  <Select
                    placeholder="Danh sách nhà cung cấp"
                    name="supplier"
                    value={productSupplierInput}
                    onChange={(value) => {
                      setProductSupplierInput(value);
                    }}
                  >
                    {supplierData.map((item) => {
                      return (
                        <>
                          <Option key={item._id} value={item._id}>
                            {item.supplier_name}
                          </Option>
                        </>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Ghi chú">
                  <TextArea
                    placeholder="Ghi chú"
                    name="note"
                    value={productNoteInput}
                    onChange={(e) => {
                      setProductNoteInput(e.target.value);
                    }}
                    style={{ height: "100px" }}
                  />
                </Form.Item>
                <Form.Item>
                  <TextArea
                    placeholder="Mô tả"
                    name="description"
                    value={productDescriptionInput}
                    onChange={(e) => {
                      setDescriptionInput(e.target.value);
                    }}
                    style={{ height: "178px" }}
                  />
                </Form.Item>
                <Form.Item label="Avatar">
                  <Input
                    type="file"
                    placeholder="Giá Sale"
                    value={productImg}
                    name="SalePrice"
                    onChange={(e) => {
                      setProductImg(e.target.value);
                    }}
                  />
                </Form.Item>
              </Card>
            </Col>
          </Row>

          <div style={{ marginTop: "15px" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Thêm
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddProduct;
