import { TagsOutlined, ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Space,
  Table,
  Tooltip,
  Modal,
  Tag,
  Typography,
  Popconfirm,
  Form,
  Select,
  Row,
  Col,
  Card,
  Input,
  Flex,
} from "antd";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useEffect, useState, useReducer } from "react";

import { apiv1 } from "../../../core/data";

import Category from "../components/sub-page/Category";
import AddProduct from "../components/sub-page/AddProduct";
import ExcelImport from "../components/sub-page/ExcelImport";
import ExcelExport from "../components/sub-page/ExcelExport";

import formatCurrency from "../../../utils/formatCurrency";
import { useDataAll } from "../../../hooks";
import TextArea from "antd/es/input/TextArea";

const { Text } = Typography;
const { Option } = Select;

const Product = () => {
  const [dataAll, dispatchDataAll] = useDataAll();
  const [categoryModal, setCategoryModal] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);

  const [updateModal, setUpdateModal] = useState(false);
  const [addProductModal, setAddProductModal] = useState(false);
  const [excelImportModal, setExcelImportModal] = useState(false);
  const [excelExportModal, setExcelExportModal] = useState(false);
  const [filterState, setFilterState] = useState(false);
  const [productData, setProductData] = useState([]);

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  //need optimize
  const [productDBIdInput, setProductDBIdInput] = useState(null);
  const [productIdInput, setProductIdInput] = useState("");
  const [productNameInput, setProductNameInput] = useState("");
  const [productUnitInput, setProductUnitInput] = useState("");
  const [productBrandInput, setProductBrandInput] = useState(null);
  const [productSupplierInput, setProductSupplierInput] = useState(null);
  const [productCategoryInput, setProductCategoryInput] = useState(null);
  const [productPriceInput, setProductPriceInput] = useState(0);
  const [productSellPriceInput, setProductSellPriceInput] = useState(0);
  const [productWholeSalePriceInput, setProductWholeSalePriceInput] =
    useState(0);
  const [productDescriptionInput, setDescriptionInput] = useState("");
  const [productNoteInput, setProductNoteInput] = useState("");
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["Sản phẩm"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  useEffect(() => {
    const getProducts = async () => {
      try {
        const res = await apiv1.get("/products", dataAll.keyHeader);
        console.log(res.data.metadata.products);
        setProductData(res.data.metadata.products);
        setFilteredProduct(res.data.metadata.products);
      } catch (error) {
        console.log(error.responsive);
      }
    };
    getProducts();
  }, [reducerValue]);

  const deleteProduct = async (id) => {
    await apiv1.delete(`/products/delete/${id}`, dataAll.keyHeader);
    forceUpdate();
  };
  console.log("productData");
  console.log(productData);
  useEffect(() => {
    const getSupplier = async () => {
      try {
        const res = await apiv1.get("/suppliers", dataAll.keyHeader);
        console.log(res.data.metadata.suppliers);
        setSupplierData(res.data.metadata.suppliers);
      } catch (error) {
        console.log(error.message);
      }
    };
    getSupplier();
  }, []);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const res = await apiv1.get("/categories", dataAll.keyHeader);
        console.log(res.data.metadata.categories);
        setCategoryData(res.data.metadata.categories);
      } catch (error) {
        console.log(error.message);
      }
    };

    getCategories();
  }, []);

  useEffect(() => {
    const getBrands = async () => {
      try {
        const res = await apiv1.get("/brands", dataAll.keyHeader);
        console.log(res.data.metadata.brands);
        setBrandData(res.data.metadata.brands);
      } catch (error) {
        console.log(error.message);
      }
    };

    getBrands();
  }, []);

  useEffect(() => {
    const getInventories = async () => {
      try {
        const res = await apiv1.get("/inventories", dataAll.keyHeader);
        setInventoryData(res.data.metadata.inventories);
      } catch (error) {
        console.log(error.message);
      }
    };

    getInventories();
  }, []);

  const handleFormUpdate = (item) => {
    setUpdateModal(true);
    setProductDBIdInput(item._id);
    setProductIdInput(item.product_id);
    setProductNameInput(item.product_name);
    setProductUnitInput(item.product_unit);
    setProductBrandInput(item.product_brand);
    setProductSupplierInput(item.product_supplier);
    setProductCategoryInput(item.product_category);
    setDescriptionInput(item.product_description);
    setProductPriceInput(item.product_price);
    setProductSellPriceInput(item.product_sell_price);
    setProductWholeSalePriceInput(item.product_wholesale_price);
    setProductNoteInput(item.product_note)
  };

  const handleFormUpdateSubmit = async (id) => {
    const productData = {
      product_id: productIdInput,
      product_name: productNameInput,
      product_unit: productUnitInput,
      product_brand: productBrandInput,
      product_supplier: productSupplierInput,
      product_category: productCategoryInput,
      product_price: productPriceInput,
      product_sell_price: productSellPriceInput,
      product_wholesale_price: productWholeSalePriceInput,
      product_description: productDescriptionInput,
      product_note: productNoteInput,
      product_sale_price: 0
    };

    await apiv1.patch(`/products/update/${id}`, productData, dataAll.keyHeader);

    window.location.reload(false);
  };

  const [filteredProduct, setFilteredProduct] = useState([]);
  const [filterInput, setFilterInput] = useState(null);

  const handleInputChange = (value) => {
    setFilterInput(value);

    const filteredItems = productData.filter(
      (product) => product.product_category === filterInput
    );
    setFilteredProduct(filteredItems);
  };

  return (
    <>
      <div>
        <Flex justify="space-between">
          <div>
            <Space>
              <Tooltip title="Thêm sản phẩm">
                <Button
                  type="primary"
                  onClick={() => {
                    setAddProductModal(true);
                  }}
                >
                  +
                </Button>
              </Tooltip>
              <Button
                icon={<TagsOutlined />}
                onClick={() => {
                  setCategoryModal(true);
                }}
              >
                Danh mục SP
              </Button>
              <Tooltip title="Nhập sản phẩm từ Excel">
                <Button
                  onClick={() => {
                    setExcelImportModal(true);
                  }}
                >
                  Nhập Excel
                </Button>
              </Tooltip>
              <Tooltip title="Xuất tất cả sản phẩm">
                <Button
                  onClick={() => {
                    setExcelExportModal(true);
                  }}
                >
                  Xuất Excel
                </Button>
              </Tooltip>
              <Button
                icon={<ReloadOutlined />}
                onClick={() => window.location.reload(false)}
              >
                Tải lại
              </Button>
            </Space>
          </div>
          <div>
            <Button
              onClick={() => {
                setFilterState(!filterState);
              }}
            >
              Lọc
            </Button>
          </div>
        </Flex>
      </div>
      <div>
        {filterState && (
          <div style={{ marginTop: "15px" }}>
            <Form>
              <Row gutter={7}>
                <Col span={6}>
                  <Form.Item>
                    <Select placeholder="Danh mục" onChange={handleInputChange}>
                      {categoryData.map((item) => {
                        return (
                          <>
                            <Option key={item._id} value={item._id}>
                              {item.category_name}
                            </Option>
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item>
                    <Select
                      placeholder="Nhà cung cấp"
                      onChange={(value) => alert(value)}
                    >
                      {supplierData.map((item) => {
                        return (
                          <>
                            <Option key={item._id} value={item._id}>
                              {item.supplier_name}
                            </Option>
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </div>
      <div style={{ marginTop: "15px" }}>
        <Table
          bordered
          dataSource={filteredProduct}
          scroll={{
            y: 280,
          }}
          pagination={{
            position: "bottomRight",
          }}
          columns={[
            {
              title: "Mã SP",
              dataIndex: "product_id",
              key: "product_id",
            },
            {
              title: "Tên SP",
              dataIndex: "product_name",
              key: "product_name",
            },
            {
              title: "Đơn vị tính",
              dataIndex: "product_unit",
              key: "product_unit",
            },
            {
              title: "Thương hiệu",
              dataIndex: ["brand", "brand_name"],
              key: "product_brand",
              filters: brandData.map((item) => {
                return {
                  text: item.brand_name,
                  value: item.brand_name,
                };
              }),
              onFilter: (value, record) =>
                record.brand.brand_name.includes(value),
            },
            {
              title: "Danh mục",
              dataIndex: ["category", "category_name"],
              key: "product_category",
              filters: categoryData.map((item) => {
                return {
                  text: item.category_name,
                  value: item.category_name,
                };
              }),
              onFilter: (value, record) =>
                record.category.category_name.indexOf(value) === 0,
            },
            {
              title: "Nhà cung cấp",
              dataIndex: ["supplier", "supplier_name"],
              key: "supplier_name",
              filters: supplierData.map((item) => {
                return {
                  text: item.supplier_name,
                  value: item.supplier_name,
                };
              }),
              onFilter: (value, record) => {
                return record.supplier.supplier_name.indexOf(value) === 0;
              },
            },
            {
              title: "Kho",
              dataIndex: "inventories",
              key: "inventories",
              render: (_, { inventories }) => (
                <>
                  {inventories.map((item) => {
                    return (
                      <>
                        <Space size="middle">
                          <Tag>{item.inventory_name}</Tag>
                        </Space>
                      </>
                    );
                  })}
                </>
              ),
            },
            {
              title: "Giá nhập",
              dataIndex: "product_price",
              key: "product_price",
              render: (currency) => (
                <>
                  <Text type="success">{formatCurrency(currency)}</Text>
                </>
              ),
              sorter: (a, b) => a.product_price - b.product_price,
              sortDirections: ["ascend", "descend"],
            },
            {
              title: "Giá bán",
              dataIndex: "product_sell_price",
              key: "product_sell_price",
              render: (currency) => (
                <>
                  <Text type="danger">{formatCurrency(currency)}</Text>
                </>
              ),
              sorter: (a, b) => a.product_price - b.product_price,
              sortDirections: ["ascend", "descend"],
            },
            {
              title: "Giá sỉ",
              dataIndex: "product_wholesale_price",
              key: "product_wholesale_price",
              render: (currency) => (
                <>
                  <Text type="warning">{formatCurrency(currency)}</Text>
                </>
              ),
              sorter: (a, b) => a.product_price - b.product_price,
              sortDirections: ["ascend", "descend"],
            },
            {
              title: "Giá Sale",
              dataIndex: "product_sale_price",
              key: "product_sale_price",
              render: (currency) => (
                <>
                  <Text type="warning">{formatCurrency(currency)}</Text>
                </>
              ),
              sorter: (a, b) => a.product_price - b.product_price,
              sortDirections: ["ascend", "descend"],
            },
            {
              title: "Hành động",
              key: "action",
              render: (item) => (
                <Space size="middle">
                  <Button onClick={() => handleFormUpdate(item)}>Sửa</Button>
                  <Popconfirm
                    title="Xóa sản phẩm"
                    onConfirm={() => {
                      deleteProduct(item._id);
                    }}
                  >
                    <Button danger>Xóa</Button>
                  </Popconfirm>
                </Space>
              ),
            },
          ]}
        ></Table>
      </div>

      <Modal
        title="Danh mục sản phẩm"
        width={1000}
        open={categoryModal}
        onCancel={() => {
          setCategoryModal(false);
        }}
        footer={null}
      >
        <div>
          <Category />
        </div>
      </Modal>

      <Modal
        title="Thêm sản phẩm"
        width={1200}
        open={addProductModal}
        onCancel={() => {
          setAddProductModal(false);
        }}
        footer={null}
      >
        <AddProduct />
      </Modal>

      <Modal
        title="Nhập sản phẩm từ excel"
        width={500}
        open={excelImportModal}
        onCancel={() => {
          setExcelImportModal(false);
        }}
        footer={null}
      >
        <ExcelImport />
      </Modal>

      <Modal
        title="Xuất danh sách sản phẩm ra Excel"
        width={500}
        open={excelExportModal}
        onCancel={() => {
          setExcelExportModal(false);
        }}
        footer={null}
      >
        <ExcelExport productData={productData} exportToCSV={exportToCSV} />
      </Modal>

      <Modal
        title="Sửa sản phẩm"
        width={1200}
        open={updateModal}
        onCancel={() => {
          setUpdateModal(false);
        }}
        footer={null}
      >
        <>
          <div>
            <Form layout="vertical">
              <Row gutter={10}>
                <Col span={12}>
                  <Card>
                    <Form.Item label="Mã sản phẩm" required>
                      <Input
                        placeholder="Mã sản phẩm"
                        name="productID"
                        value={productIdInput}
                        onChange={(e) => {
                          setProductIdInput(e.target.value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="Tên sản phẩm" required>
                      <Input
                        placeholder="Tên sản phẩm"
                        name="product"
                        value={productNameInput}
                        onChange={(e) => {
                          setProductNameInput(e.target.value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="Đơn vị tính">
                      <Input
                        placeholder="Đơn vị tính"
                        name="unit"
                        value={productUnitInput}
                        onChange={(e) => {
                          setProductUnitInput(e.target.value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="Giá nhập (VND)">
                      <Input
                        placeholder="Giá nhập"
                        name="price"
                        value={productPriceInput}
                        onChange={(e) => {
                          setProductPriceInput(e.target.value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="Giá bán (VND)">
                      <Input
                        placeholder="Giá bán"
                        name="sellPrice"
                        value={productSellPriceInput}
                        onChange={(e) => {
                          setProductSellPriceInput(e.target.value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="Giá sỉ (VND)">
                      <Input
                        placeholder="Giá sỉ"
                        name="wholeSalePrice"
                        value={productWholeSalePriceInput}
                        onChange={(e) => {
                          setProductWholeSalePriceInput(e.target.value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="Thương hiệu">
                      <Select
                        placeholder="Danh sách thương hiệu"
                        name="brand"
                        value={productBrandInput}
                        onChange={(value) => {
                          setProductBrandInput(value);
                        }}
                      >
                        {brandData.map((item) => {
                          return (
                            <>
                              <Option key={item._id} value={item._id}>
                                {item.brand_name}
                              </Option>
                            </>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Card>
                </Col>

                <Col span={12}>
                  <Card>
                    <Form.Item label="Danh mục">
                      <Select
                        placeholder="Danh sách danh mục"
                        name="category"
                        value={productCategoryInput}
                        onChange={(value) => {
                          setProductCategoryInput(value);
                        }}
                      >
                        {categoryData.map((item) => {
                          return (
                            <>
                              <Option key={item._id} value={item._id}>
                                {item.category_name}
                              </Option>
                            </>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item label="Nhà cung cấp">
                      <Select
                        placeholder="Danh sách nhà cung cấp"
                        name="supplier"
                        value={productSupplierInput}
                        onChange={(value) => {
                          setProductSupplierInput(value);
                        }}
                      >
                        {supplierData.map((item) => {
                          return (
                            <>
                              <Option key={item._id} value={item._id}>
                                {item.supplier_name}
                              </Option>
                            </>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item label="Ghi chú">
                      <TextArea
                        placeholder="Ghi chú"
                        name="note"
                        value={productNoteInput}
                        onChange={(e) => {
                          setProductNoteInput(e.target.value);
                        }}
                        style={{ height: "100px" }}
                      />
                    </Form.Item>
                    <Form.Item label="Mô tả">
                      <TextArea
                        placeholder="Mô tả"
                        name="description"
                        value={productDescriptionInput}
                        onChange={(e) => {
                          setDescriptionInput(e.target.value);
                        }}
                        style={{ height: "178px" }}
                      />
                    </Form.Item>
                  </Card>
                </Col>
              </Row>

              <div style={{ marginTop: "15px" }}>
                <Form.Item>
                  <Popconfirm
                    title="Xác nhận thay đổi ?"
                    onConfirm={() => {
                      handleFormUpdateSubmit(productDBIdInput);
                    }}
                  >
                    <Button type="primary" htmlType="submit">
                      Sửa
                    </Button>
                  </Popconfirm>
                </Form.Item>
              </div>
            </Form>
          </div>
        </>
      </Modal>
    </>
  );
};

export default Product;
