import { SHOW_ADD_STAFF } from "./action"

const showAddStaff = value => {
    return {
        type: SHOW_ADD_STAFF,
        value: value
    }
}
export {
    showAddStaff
}