import { Button, Flex, Form, Input, Space } from "antd";
import FormItem from "antd/es/form/FormItem";
import styled from "styled-components";
const SectionAddStaff = styled.section``
const FormAddStaff = styled(Form)``
const FlexAddStaff = styled(Flex)``
const SpaceAddStaff = styled(Space)``
const BoxAddStaff = styled(FormItem)``
const InputAddStaff = styled(Input)``
const BtnSubmit = styled(Button)``
export {
    SectionAddStaff,
    FlexAddStaff,
    SpaceAddStaff,
    BoxAddStaff,
    InputAddStaff,
    BtnSubmit,
    FormAddStaff,
}