import BlankLayout from "../../components/layouts/BlankLayout"
import { path } from "../../routers/path"
import ForgotPassword from "./page"
const router = [
    {
        path:path.forgotPassword,
        page:ForgotPassword,
        exact:true,
        layout: BlankLayout,
        checkLogin: false,
    } 
]
export default router