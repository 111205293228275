import { path } from "../../routers/path"
import Home from "./page"
import DefaultLayout from "../../components/layouts/Default"
const routers = [
    {
        path:path.dashboard,
        page:Home,
        layout:DefaultLayout,
    }
]
export default routers