import { ADD_ACCESS_TOKEN, ADD_DATA_ACCOUNT, ADD_ID_USER, ADD_USER_INFORMATION } from "./action";

const addDataAccount = (value) => {
  return {
    type: ADD_DATA_ACCOUNT,
    value: value,
  };
};
const addAccessToken = (value) => {
  return {
    type: ADD_ACCESS_TOKEN,
    value: value,
  };
};
const addIdUser = (value) => {
  return {
    type: ADD_ID_USER,
    value: value,
  };
};
const addUserInformation = value => {
  return {
    type: ADD_USER_INFORMATION,
    value:value
  }
}
export { 
    addDataAccount,
    addAccessToken,
    addIdUser,
    addUserInformation
};
