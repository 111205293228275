const ThemeDark = {
    text1:'white',
    text2:'black',
    text3:'greenyellow',
    text4:'red',
    text5:'skyblue',
    text6:'#333',
    text7:'#38a8e8',
    text8:'#ccffff',
    text9:'#ffcccc',
    text10:'#e91e63',
    text11:'#101010',
    text12:'#9763f6',
    colorDeco1:'#333',
    colorDeco2:'red',
    colorDeco3:'skyblue',
    colorDeco4:'aqua',
    colorDeco5:'yellowgreen',
    colorDeco6:'greenyellow',
    colorDeco7:'white',
    colorDeco8:'gray',
    colorDeco9:'grey',
    colorDeco10:'#ffcccc',
    colorDeco11:'#ccffff',
    colorDeco12:'black',
    colorDeco13:'pink',
    colorDeco14:'#38a8e8',
    colorDeco15:'orange',
    colorDeco16:'chocolate',
    colorDeco17:'#e91e63',
    colorDeco18:'linear-gradient( 35deg, #ccffff,#ffcccc)',
    colorDeco19:'rgba(255, 255, 255, 0.212)', //transparent
    colorDeco20:'#101010',
    colorDeco21:'#9763f6',
    colorDeco22:'rgba(128, 128, 128, 0.548)', //transparent
    colorDeco23:'linear-gradient( 35deg, #5fffff,#ff8080)',
}

export {
    ThemeDark
}