import { path } from "../../routers/path"
import Order from "./page"
import DefaultLayout from "../../components/layouts/Default"

const routers = [
    {
        path:path.orders,
        page:Order,
        layout:DefaultLayout,
        exact:true
    }
]
export default routers