import React, { useEffect, useState, useReducer } from "react";

import {
  Table,
  Space,
  Tooltip,
  Modal,
  Button,
  Popconfirm,
  Form,
  Input,
  Tag,
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";

import { useDataAll } from "../../../hooks";
import { apiv1 } from "../../../core/data";
import AddOrder from "../components/AddOrder";
import { BoxTable } from "../components/style";
import OrderDetail from "../components/orderDetail";

const Product = () => {
  const [dataAll, dispatchDataAll] = useDataAll();
  const [orderData, setOrderData] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [statusItemOrderData, setStatusItemOrderData] = useState();
  useEffect(() => {
    const getOrders = async () => {
      try {
        const res = await apiv1.get("/orders", dataAll.keyHeader);
        setOrderData(res.data.metadata.orders);
      } catch (error) {
        console.error(error.message);
      }
    };
    getOrders();
  }, []);
  const HandleDelete = async (id) => {
    try {
      await apiv1.delete(`/orders/delete/${id}`, dataAll.keyHeader);
      window.location.reload();
    } catch (error) {
      console.error(error.message);
    }
  };
  const HandleCompleted = async (id) => {
    try {
      const resOrderDetail = await apiv1.get(
        `/order/detail/${id}`,
        dataAll.keyHeader
      );
      const dataResOrderDetail = resOrderDetail.data.metadata.data;
      console.log(dataResOrderDetail);
      // dataResOrderDetail.map(async(value) => {
      // console.log({
      //   productId: value.productId,
      //   quantity: value.quantity,
      //   // inventoryId: resInventoryId
      //   // order_name: value.order.order_name,
      //   // order_customer: value.order.order_customer,
      //   // order_status:"completed"
      // });
      // });
      // const req = await apiv1.post(`/order/confirm`,{},dataAll.keyHeader)
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    const filterOrderData = orderData.filter((item) => item._id === orderId);
    filterOrderData.map((value) => {
      setStatusItemOrderData(value.order_status);
    });
  }, [orderId]);
  const CheckStatusItemOrderData = () => {
    if (statusItemOrderData === "completed") {
      return (
        <Button
          style={{ width: "40%" }}
          type="primary"
          danger
          onClick={() => HandleDelete(orderId)}
        >
          Delete
        </Button>
      );
    }
    if (statusItemOrderData === "pending") {
      return (
        <BoxTable>
          {console.log(statusItemOrderData)}
          <Button
            style={{ width: "40%" }}
            type="primary"
            onClick={() => HandleCompleted(orderId)}
          >
            Completed
          </Button>
          <Button
            style={{ width: "40%" }}
            type="primary"
            danger
            onClick={() => HandleDelete(orderId)}
          >
            Delete
          </Button>
        </BoxTable>
      );
    }
  };
  return (
    <>
      <div>
        <Space>
          <Tooltip title="Thêm đơn hàng">
            <Button
              type="primary"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              +
            </Button>
          </Tooltip>
          <Button
            icon={<ReloadOutlined />}
            onClick={() => window.location.reload(false)}
          >
            Tải lại
          </Button>
        </Space>
      </div>
      <div style={{ marginTop: "15px" }}>
        <Table
          bordered
          dataSource={orderData}
          scroll={{
            y: 320,
          }}
          pagination={{
            position: "bottomRight",
          }}
          onRow={(record, index) => {
            return {
              onClick: () => {
                setOrderId(record._id);
                setShowOrderDetail(true);
              },
            };
          }}
          columns={[
            {
              title: "Mã đơn",
              dataIndex: "_id",
              key: "_id",
            },
            {
              title: "Tên đơn",
              dataIndex: "order_name",
              key: "order_name",
            },
            {
              title: "Thời gian đặt",
              dataIndex: "order_time",
              key: "order_time",
            },
            {
              title: "Trạng thái",
              dataIndex: "order_status",
              key: "order_status",
              render: (order_status) => {
                const color = () => {
                  if (order_status === "pending") {
                    return "geekblue";
                  }
                  if (order_status === "completed") {
                    return "green";
                  }
                  if (order_status === "deleted") {
                    return "red";
                  }
                };
                return (
                  <Tag color={color()} key={order_status}>
                    {order_status}
                  </Tag>
                );
              },
            },
            {
              title: "Khách hàng",
              dataIndex: ["customer", "customer_name"],
              key: "customer_name",
            },
            {
              title: "Số điện thoại",
              dataIndex: ["customer", "customer_phone"],
              key: "customer_phone",
            },
          ]}
        />
      </div>

      <Modal
        title="Tạo đơn"
        open={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
        footer={false}
      >
        <AddOrder />
      </Modal>
      <Modal
        title="Chi tiết đơn hàng"
        open={showOrderDetail}
        onCancel={() => {
          setShowOrderDetail(false);
        }}
        footer={false}
      >
        <OrderDetail id={orderId} />
        <CheckStatusItemOrderData />
      </Modal>
    </>
  );
};

export default Product;
