/**Number or string parse to VND format */

const formatCurrency = (x) => {
  let numberValue = Number(x);
  numberValue = numberValue.toLocaleString("it-IT", {
    style: "currency",
    currency: "VND",
  });
  return numberValue;
};

export default formatCurrency;
