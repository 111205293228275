import React, { useState, useEffect } from "react";
import { Input, Button, Form, Select, InputNumber } from "antd";
import { apiv1 } from "../../../../core/data";
import { useDataAll } from "../../../../hooks";

const { Option } = Select;
const ChangeProductInventory = () => {
  const [dataAll, dispatchDataAll] = useDataAll();

  const [productIdInput, setProductIdInput] = useState(null);
  const [inventoryFromIdInput, setInventoryFromIdInput] = useState(null);
  const [inventoryToIdInput, setInventoryToIdInput] = useState(null);
  const [quantityInput, setQuantityInput] = useState(null);
  const [quantityTo,setQuantityTo] = useState([])

  const [productData, setProductData] = useState([]);
  const [inventoryFromData, setInventoryFromData] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const res = await apiv1.get("/products", dataAll.keyHeader);
        setProductData(res.data.metadata.products);
      } catch (error) {
        console.log(error);
      }
    };

    getProducts();
  }, []);

  useEffect(() => {
    const getToInventories = async () => {
      try {
        const res = await apiv1.get("/inventories", dataAll.keyHeader);
        setInventoryData(res.data.metadata.inventories);
      } catch (error) {
        console.log(error);
      }
    };

    getToInventories();
  }, []);

  useEffect(() => {
    const getInventoriesByProduct = async () => {
      try {
        const res = await apiv1.get(
          `/inventory_by_product/${productIdInput}`,
          dataAll.keyHeader
        );
        const data = res.data.metadata.inventoryByProduct;
        setInventoryFromData(data);
        const quantityData = data.filter(
          (item) => item.InventoryId === inventoryFromIdInput
        );
        setQuantityTo(quantityData)
      } catch (error) {
        console.log(error);
      }
    };

    getInventoriesByProduct();
  }, [productIdInput,inventoryFromIdInput]);

  const handleConfirm = async () => {
    try {
      const data = {
        productId: productIdInput,
        fromId: inventoryFromIdInput,
        toId: inventoryToIdInput,
        quantity: quantityInput,
      };
      console.log(dataAll.keyHeader);
      await apiv1.post('/change_inventories',data,dataAll.keyHeader)
    } catch (error) {
      console.log(error.message);
      // alert("Lỗi");
    }
  };

  return (
    <>
      <Form layout="vertical" onSubmitCapture={handleConfirm}>
        <Form.Item label="Sản phẩm">
          <Select
            placeholder="Danh sách sản phẩm"
            value={productIdInput}
            onChange={(value) => {
              setProductIdInput(value);
            }}
          >
            {productData.map((item) => {
              return (
                <>
                  <Option key={item._id} value={item._id}>
                    {item.product_name}
                  </Option>
                </>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Kho đi">
          <Select
            placeholder="Kho đi"
            value={inventoryFromIdInput}
            onChange={(value) => {
              setInventoryFromIdInput(value);
            }}
          >
            {inventoryFromData.map((item) => {
              return (
                <>
                  <Option key={item.inventoryId} value={item.inventoryId}>
                    {item.inventory.inventory_name}
                  </Option>
                </>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Số lượng đang có">
          {quantityTo.map((item) => {
            return <Input value={item.quantity} />;
          })}
        </Form.Item>
        <Form.Item label="Số lượng chuyển đi">
          <Input
            type="number"
            name="quantity"
            value={quantityInput}
            onChange={(e) => {
              setQuantityInput(e.target.value);
            }}
          />
        </Form.Item>

        <Form.Item label="Kho đến">
          <Select
            placeholder="Kho đến"
            value={inventoryToIdInput}
            onChange={(value) => {
              setInventoryToIdInput(value);
            }}
          >
            {inventoryData.map((item) => {
              return (
                <>
                  <Option key={item._id} value={item._id}>
                    {item.inventory_name}
                  </Option>
                </>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Chuyển hàng
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ChangeProductInventory;
