import React, { useState } from "react";
import { Input, Button, Form } from "antd";

import { apiv1 } from "../../../core/data";
import { useDataAll } from "../../../hooks";
import FormItem from "antd/es/form/FormItem";
import { CheckStatus } from "../../../core/status";

const AddBrand = () => {
  const [dataAll, dispatchDataAll] = useDataAll();

  const [brandNameInput, setBrandNameInput] = useState("");

  const handleSubmit = async () => {
    const dataBrand = {
      brand_name: brandNameInput,
    };
    try {
      await apiv1.post("/brands", dataBrand, dataAll.keyHeader);
      window.location.reload(false);
    } catch (err) {
      const statusCode = err.response.data.code;
      const messageCode = err.response.data.message;
      // CheckStatus(statusCode, messageCode);
    }
  };

  return (
    <>
      <Form layout="vertical">
        <Form.Item label="Tên thương hiệu" required>
          <Input
            placeholder="Tên thương hiệu"
            name="name"
            value={brandNameInput}
            onChange={(e) => {
              setBrandNameInput(e.target.value);
            }}
          />
        </Form.Item>
        <FormItem>
          <Button type="primary" htmlType="submit" onClick={handleSubmit}>
            Thêm
          </Button>
        </FormItem>
      </Form>
    </>
  );
};

export default AddBrand;