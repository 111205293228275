import { path } from "../../routers/path"
import Product from "./page"
import DefaultLayout from "../../components/layouts/Default"
const routers = [
    {
        path:path.products,
        page:Product,
        layout:DefaultLayout,
    }
]
export default routers