import { SET_CONFIRM_PASSWORD, SET_NEW_PASSWORD, SET_OLD_PASSWORD } from "./action"

const setOldPassword = value => {
    return{
        type:SET_OLD_PASSWORD,
        value:value
    }
}
const setNewPassword = value => {
    return{
        type:SET_NEW_PASSWORD,
        value:value
    }
}
const setConfirmPassword = value => {
    return{
        type:SET_CONFIRM_PASSWORD,
        value:value
    }
}
export {
    setOldPassword,
    setNewPassword,
    setConfirmPassword,
}