import {
  BrowserRouter as Routers,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { rootRouter } from "./router";
import { useEffect, useState } from "react";
import { useDataAll } from "../hooks";
import BlankLayout from "../components/layouts/BlankLayout";
import Login from "../module/login/page";
import { path } from "./path";
import ForgotPassword from "../module/forgotPassword/page";
const AppRouter = () => {
  const [dataAll] = useDataAll();
  const [Authorticate, setAuthorticate] = useState(false);
  useEffect(() => {
    if (dataAll.keyHeader.headers.Authorization) {
      setAuthorticate(true);
    }
  }, [dataAll.keyHeader.headers.Authorization]);
  return (
    <Routers>
      <Routes>
        <Route
          path={path.login}
          element={
            <BlankLayout>
              <Login />
            </BlankLayout>
          }
        />
        <Route
          path={path.forgotPassword}
          element={
            <BlankLayout>
              <ForgotPassword />
            </BlankLayout>
          }
        />
        {dataAll.keyHeader.headers.Authorization ? (
          rootRouter.map((value, index) => {
            const Page = value.page;
            const Layout = value.layout;
            const checkLogin = value.checkLogin;
            const runRoutes =
              checkLogin === false || Authorticate === true ? (
                <Route
                  key={index}
                  path={value.path}
                  element={
                    <Layout>
                      <Page />
                    </Layout>
                  }
                />
              ) : null;
            return runRoutes;
          })
        ) : (
          <Route key="login" path="/*" element={<Navigate to="/" replace />} />
        )}
      </Routes>
    </Routers>
  );
};

export default AppRouter;
