import styled from "styled-components";

const ProfileBox = styled.div`
  position: absolute;
  right: 0;
  top: 11%;
  width: 200px;
  display: none;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colorDeco9};
  border-radius: 20px;
  background: ${({ theme }) => theme.colorDeco7};
  z-index: 99;
`;
const Profile = styled.div`
  width: 200px;
  height: 100%;
  margin-right:2%;
  &:hover {
    ${ProfileBox} {
      display: flex;
    }
  }
`;
const ProfileBoxItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ProfileBlock = styled.div`
width:100%;
height:100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
export { Profile, ProfileBoxItem, ProfileBox, ProfileBlock };
