import { createContext, useReducer } from "react";
import { initDataAll, reducerDataAll } from "./reducer";
const AppAllProvider = createContext();
function AppProvider({ children }) {
  const [dataAll, dispatchDataAll] = useReducer(reducerDataAll,initDataAll);
  return (
    <AppAllProvider.Provider value={[dataAll, dispatchDataAll]}>
      {children}
    </AppAllProvider.Provider>
  );
}
export{
    AppAllProvider
}
export default AppProvider;
