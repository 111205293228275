import { path } from  "../../routers/path"
import DefaultLayout from "../../components/layouts/Default"
import Brand from "./pages"
const routers = [
    {
        path:path.brand,
        page:Brand,
        layout:DefaultLayout

    }
]

export default routers