import {
  SET_CONFIRM_PASSWORD,
  SET_NEW_PASSWORD,
  SET_OLD_PASSWORD,
} from "./action";

const initChangePassword = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};
const reducerChange = (state, action) => {
  switch (action.type) {
    case SET_OLD_PASSWORD:
      return {
        ...state,
        oldPassword:action.value
      };
    case SET_NEW_PASSWORD:
      return {
        ...state,
        newPassword:action.value
      };
    case SET_CONFIRM_PASSWORD:
      return {
        ...state,
        confirmPassword:action.value
      };
    default:
      console.error("invalid reducerChange");
      return;
  }
};
export { initChangePassword, reducerChange };
