import {
  BoxAddStaff,
  FlexAddStaff,
  FormAddStaff,
  SectionAddStaff,
  SpaceAddStaff,
  InputAddStaff
} from "../style";

const AddStaff = () => {
  return (
    <SectionAddStaff>
      <FormAddStaff>
        {/* <FlexAddStaff justify="space-between">
          <SpaceAddStaff>
            
          </SpaceAddStaff>
        </FlexAddStaff> */}
        <BoxAddStaff label="Fullname">
              <InputAddStaff />
            </BoxAddStaff>
            <BoxAddStaff label="Username">
              <InputAddStaff />
            </BoxAddStaff>
            <BoxAddStaff label="Password">
              <InputAddStaff />
            </BoxAddStaff>
            <BoxAddStaff label="Email">
              <InputAddStaff />
            </BoxAddStaff>
            <BoxAddStaff label="Role">
              <InputAddStaff />
            </BoxAddStaff>
      </FormAddStaff>
    </SectionAddStaff>
  );
};
export default AddStaff;
