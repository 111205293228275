import React, { useState, useEffect, useReducer } from "react";
import { Table, Space, Tooltip, Modal, Button, Popconfirm, Flex } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

import { Input, Form } from "antd";
import AddBrand from "../components/addBrand"

import { apiv1 } from "../../../core/data";
import { useDataAll } from "../../../hooks";
import { CheckStatus } from "../../../core/status";

const Brand = () => {
  const [dataAll, dispatchDataAll] = useDataAll();
  const [brandData, setBrandData] = useState([]);
  const [open,setOpen] = useState(false)

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    const getBrands = async () => {
      try {
        const res = await apiv1.get("/brands", dataAll.keyHeader);
        setBrandData(res.data.metadata.brands);
      } catch (error) {
        const statusCode = error.response.data.code
        const messageCode = error.response.data.message
        // CheckStatus(statusCode, messageCode)
      }
    };

    getBrands();
  }, [reducerValue]);

  return (
    <>
      <div>
        <Flex align="center" justify="space-between">
          <div>
            <Space>
              <Tooltip title="Thêm thương hiệu">
                <Button
                  type="primary"
                  onClick={() => {
                    setOpen(true)
                  }}
                >
                  +
                </Button>
              </Tooltip>
              <Button
                icon={<ReloadOutlined />}
                onClick={() => window.location.reload(false)}
              >
                Tải lại
              </Button>
            </Space>
          </div>
        </Flex>
      </div>
      <div style={{ marginTop: "15px" }}>
        <Table
          bordered
          dataSource={brandData}
          columns={[
            {
              title: "Mã thương hiệu",
              dataIndex: "_id",
              key: "_id",
            },
            {
              title: "Tên thương hiệu",
              dataIndex: "brand_name",
              key: "brand_name",
            },
            {
                title:"Hành động",
                render:(item) => {
                    return (
                        <>
                            <Space>
                                <Button>
                                    Sửa
                                </Button>
                                <Popconfirm
                                    title="Xóa nhà cung cấp"
                                    onConfirm={() => {
                                        alert("Hello")
                                    }}
                                >
                                    <Button>Xóa</Button>
                                </Popconfirm>
                            </Space>
                        </>
                    )
                }
            }
          ]}
        ></Table>
      </div>

      <Modal title="Thêm thương hiệu"
      open = {open}
      onCancel={() => {
        setOpen(false)
      }}

      footer = {false}
      >
        <AddBrand />
      </Modal>
    </>
  );
};

export default Brand;
