import React, { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  SkinOutlined,
  UserSwitchOutlined,
  UserOutlined,
  LogoutOutlined,
  HomeOutlined,
  TagOutlined,
  ShoppingOutlined,
  SettingOutlined,
  ControlOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import { Layout, Menu, Button, theme, Flex, Avatar, Dropdown } from "antd";
import { path } from "../../routers/path";
import { apiv1 } from "../../core/data";
import { useDataAll } from "../../hooks";
import { Profile, ProfileBlock } from "./style";
const { Header, Sider, Content } = Layout;

const DefaultLayout = ({ children }) => {
  const navigate = useNavigate();
  const StorageAccount = JSON.parse(localStorage.getItem("data_account"));
  const StorageKeyHeader = JSON.parse(localStorage.getItem("keyheader"));
  const [dataAll, dispatchDataAll] = useDataAll();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  useEffect(() => {
    if (!dataAll.keyHeader.headers.Authorization) {
      navigate("/");
    }
  }, [dataAll.keyHeader.headers.Authorization]);
  const HandleLogout = async () => {
    try {
      const res = await apiv1.post("/logout", {}, dataAll.keyHeader);
      if (res.data.status === 200) {
        if (StorageAccount && StorageKeyHeader) {
          navigate("/");
          return localStorage.clear();
        }
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const items = [
    {
      key: "1",
      label: (
        <Link
          to={path.profile}
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <FaUserCircle /> My Profile
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          onClick={() => HandleLogout()}
          style={{
            color: "red",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <LogoutOutlined /> Logout
        </Link>
      ),
    },
  ];
  // console.log(dataAll.dataAccount.metadata.user.user_role);
  return (
    <>
      <Layout className="container">
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div style={{ margin: "30px 0" }}>
            <Flex align="center" justify="center">
              <Avatar
                size={collapsed ? "default" : 64}
                icon={<UserOutlined />}
              />
            </Flex>
            <p
              style={
                collapsed
                  ? { display: "none" }
                  : { color: "white", textAlign: "center" }
              }
            >
              {dataAll.dataAccount.metadata.user.user_fullname}
            </p>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            items={[
              {
                key: "1",
                icon: <HomeOutlined />,
                label: <Link to={path.dashboard}>Tổng quan</Link>,
              },
              {
                key: "2",
                icon: <SkinOutlined />,
                label: <Link to={path.products}>Sản phẩm</Link>,
              },
              {
                key: "3",
                icon: <UserSwitchOutlined />,
                label: <Link to={path.suppliers}>Nhà cung cấp</Link>,
              },
              {
                key: "4",
                icon: <TagOutlined />,
                label: <Link to={path.brand}>Thương hiệu</Link>,
              },
              {
                key: "5",
                icon: <UploadOutlined />,
                label: <Link to={path.inventories}>Kho hàng</Link>,
              },
              {
                key: "6",
                icon: <UserOutlined />,
                label: <Link to={path.customers}>Khách hàng</Link>,
              },
              {
                key: "7",
                icon: <ShoppingOutlined />,
                label: <Link to={path.orders}>Đơn hàng</Link>,
              },
              {
                key: "8",
                icon: <SettingOutlined />,
                label: <div>Cài đặt</div>,
                children: [
                  {
                    key: "9",
                    icon: <UnorderedListOutlined />,
                    label: <Link to={path.managerStaff}>Quản lý </Link>,
                  },
                  {
                    key: "10",
                    icon: <ControlOutlined />,
                    label: <Link to={path.config}>Cấu hình </Link>,
                  },
                ],
              },
            ]}
          />
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
            }}
          >
            <Flex
              justify="space-between"
              style={{ height: "100%" }}
              align="center"
            >
              <div>
                <Button
                  type="text"
                  icon={
                    collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                  }
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    fontSize: "16px",
                    width: 64,
                    height: 64,
                  }}
                />
              </div>
              <Profile>
                <Dropdown
                  menu={{
                    items,
                  }}
                >
                  <ProfileBlock>
                    <div style={{ height: "100%" }}>
                      <Avatar
                        size={collapsed ? "default" : 50}
                        icon={<UserOutlined />}
                      />
                    </div>
                    <div style={{ height: "100%" }}>
                      <h3
                        style={{
                          margin: "0",
                          height: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {dataAll.dataAccount.metadata.user.user_fullname}
                      </h3>
                      <p
                        style={{
                          margin: "0",
                          height: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        hello myfriends
                      </p>
                    </div>
                  </ProfileBlock>
                </Dropdown>
              </Profile>
            </Flex>
          </Header>
          <Content
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default DefaultLayout;
