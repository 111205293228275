import { useEffect, useReducer } from "react";
import {
  BlockManagerStaff,
  BoxManagerStaff,
  BtnManagerStaff,
  FlexManagerStaff,
  ModalManagerStaff,
  SectionManagerStaff,
  SpaceManagerStaff,
  TableManagerStaff,
  TooltipManagerStaff,
} from "../style";
import { apiv1 } from "../../../core/data/api";
import { initShow, reducerShow, showAddStaff } from "../store";
import { AddStaff } from "../components";

const ManagerStaff = () => {
  const [show, setShow] = useReducer(reducerShow, initShow);
  useEffect(() => {
    const func = async () => {
      try {
        const req = await apiv1.post("/signup");
      } catch (err) {}
    };
  }, []);
  const tableHeader = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
  ]
  return (
    <SectionManagerStaff>
      <BlockManagerStaff>
        <FlexManagerStaff justify="space-between">
          <SpaceManagerStaff>
            <BoxManagerStaff>
              <TooltipManagerStaff title="Thêm Nhân viên">
                <BtnManagerStaff
                  type="primary"
                  onClick={() => setShow(showAddStaff(true))}
                >
                  +
                </BtnManagerStaff>
              </TooltipManagerStaff>
            </BoxManagerStaff>
          </SpaceManagerStaff>
        </FlexManagerStaff>
      </BlockManagerStaff>
      <BlockManagerStaff>
        <TableManagerStaff/>
      </BlockManagerStaff>
      <ModalManagerStaff
        title="Thêm nhân viên "
        width={1000}
        open={show.showAddStaff}
        onCancel={() => setShow(showAddStaff(false))}
        footer={null}
      >
        <AddStaff/>
      </ModalManagerStaff>
    </SectionManagerStaff>
  );
};
export default ManagerStaff;
