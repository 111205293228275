import { path } from "../../routers/path"
import NotFound from "./page"
import BlankLayout from "../../components/layouts/BlankLayout"
const routers = [
    {
        path:path.notfound,
        page:NotFound,
        layout:BlankLayout,
    }
]
export default routers