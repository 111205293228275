import React, { useEffect, useState, useReducer } from "react";

import {
  Table,
  Space,
  Tooltip,
  Modal,
  Button,
  Popconfirm,
  Form,
  Input,
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";

import AddInventory from "../components/sub-page/AddInventory";
import AddProduct from "../components/sub-page/AddProduct";
import InventoryProduct from "../components/sub-page/InventoryProduct";
import DetailInventory from "../components/sub-page/DetailInventory";
import ChangeProductInventory from "../components/sub-page/ChangeProductInventory";
import { useDataAll } from "../../../hooks";
import { apiv1 } from "../../../core/data";

const Inventory = () => {
  const [dataAll, dispatchDataAll] = useDataAll();
  const [inventoryData, setInventoryData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  const [open, setOpen] = useState(false);
  const [inventoryUpdateModal, setInventoryUpdateModal] = useState(false);
  const [inventoryProductModal, setInventoryProductModal] = useState(false);
  const [inventoryAddProductModal, setInventoryAddProductModal] =
    useState(false);
  const [inventoryDetailModal, setInventoryDetailModal] = useState(false);
  const [inventoryChangeProductModal, setInventoryChangeProductModal] =
    useState(false);

  const [inventoryNameInput, setInventoryNameInput] = useState("");
  const [inventoryAddressInput, setInventoryAddressInput] = useState("");
  const [inventoryPhoneInput, setInventoryPhoneInput] = useState("");
  const [inventoryIdInput, setInventoryIdInput] = useState(null);

  const [test, setTest] = useState(null);

  const handleFormUpdate = (item) => {
    setInventoryUpdateModal(true);
    setInventoryIdInput(item._id);
    setInventoryNameInput(item.inventory_name);
    setInventoryAddressInput(item.inventory_address);
    setInventoryPhoneInput(item.inventory_phone);
  };

  const handleFormUpdateSubmit = async (id) => {
    const inventoryData = {
      inventory_name: inventoryNameInput,
      inventory_address: inventoryAddressInput,
      inventory_phone: inventoryPhoneInput,
    };

    await apiv1.patch(
      `inventories/update/${id}`,
      inventoryData,
      dataAll.keyHeader
    );
    window.location.reload(false);
  };

  useEffect(() => {
    setLoading(true);
    const getInventories = async () => {
      try {
        const res = await apiv1.get("/inventories", dataAll.keyHeader);
        setInventoryData(res.data.metadata.inventories);
        console.log(res.data.metadata.inventories);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    };

    getInventories();
  }, [reducerValue]);

  const deleteInventory = async (id) => {
    await apiv1.delete(`/inventories/delete/${id}`, dataAll.keyHeader);
    forceUpdate();
  };

  return (
    <>
      <div>
        <Space>
          <Tooltip title="Thêm nhà kho">
            <Button
              type="primary"
              onClick={() => {
                setOpen(true);
              }}
            >
              +
            </Button>
          </Tooltip>
          <Tooltip>
            <Button
              onClick={() => {
                setInventoryProductModal(true);
              }}
            >
              Hàng tồn kho
            </Button>
          </Tooltip>
          <Tooltip>
            <Button
              onClick={() => {
                setInventoryChangeProductModal(true);
              }}
            >
              Chuyển kho
            </Button>
          </Tooltip>
          <Tooltip>
            <Button
              onClick={() => {
                setInventoryAddProductModal(true);
              }}
            >
              Thêm hàng
            </Button>
          </Tooltip>
          <Button
            icon={<ReloadOutlined />}
            onClick={() => window.location.reload(false)}
          >
            Tải lại
          </Button>
        </Space>
      </div>
      <div style={{ marginTop: "15px" }}>
        <Table
          loading={loading}
          bordered
          dataSource={inventoryData}
          onRow={(record, index) => {
            return {
              onClick: () => {
                setTest(record._id);
                setInventoryDetailModal(true);
              },
            };
          }}
          columns={[
            {
              title: "Mã kho",
              dataIndex: "_id",
              key: "_id",
            },
            {
              title: "Tên kho",
              dataIndex: "inventory_name",
              key: "inventory_name",
            },
            {
              title: "Địa chỉ",
              dataIndex: "inventory_address",
              key: "inventory_address",
            },
            {
              title: "Số điện thoại",
              dataIndex: "inventory_phone",
              key: "inventory_phone",
            },
            {
              title: "Hành động",
              render: (item) => {
                return (
                  <>
                    <Space>
                      <Button
                        onClick={() => {
                          handleFormUpdate(item);
                        }}
                      >
                        Sửa
                      </Button>
                      <Popconfirm
                        title="Xóa kho"
                        onConfirm={() => {
                          deleteInventory(item._id);
                        }}
                      >
                        <Button danger>Xóa</Button>
                      </Popconfirm>
                    </Space>
                  </>
                );
              },
            },
          ]}
        ></Table>
      </div>

      <Modal
        title="Thêm nhà kho"
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        footer={false}
      >
        <AddInventory />
      </Modal>

      <Modal
        title="Hàng tồn kho"
        open={inventoryProductModal}
        onCancel={() => {
          setInventoryProductModal(false);
        }}
        footer={false}
      >
        <InventoryProduct />
      </Modal>

      <Modal
        title="Thêm hàng"
        open={inventoryAddProductModal}
        onCancel={() => {
          setInventoryAddProductModal(false);
        }}
        footer={false}
      >
        <AddProduct />
      </Modal>

      <Modal
        title="Sửa nhà kho"
        open={inventoryUpdateModal}
        onCancel={() => {
          setInventoryUpdateModal(false);
        }}
        footer={false}
      >
        <>
          <div>
            <Form layout="vertical">
              <Form.Item label="Hàng tồn kho" required>
                <Input
                  placeholder="Hàng tồn kho"
                  name="supplier"
                  value={inventoryNameInput}
                  onChange={(e) => {
                    setInventoryNameInput(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label="Địa chỉ">
                <Input
                  placeholder="Địa chỉ"
                  name="address"
                  value={inventoryAddressInput}
                  onChange={(e) => {
                    setInventoryAddressInput(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label="Số điện thoại">
                <Input
                  placeholder="Số điện thoại"
                  name="phone"
                  value={inventoryPhoneInput}
                  onChange={(e) => {
                    setInventoryPhoneInput(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Space>
                  <Popconfirm
                    title="Xác nhập thay đổi ?"
                    onConfirm={() => {
                      handleFormUpdateSubmit(inventoryIdInput);
                    }}
                  >
                    <Button type="primary" htmlType="submit">
                      Sửa
                    </Button>
                  </Popconfirm>
                </Space>
              </Form.Item>
            </Form>
          </div>
        </>
      </Modal>

      <Modal
        title="Chi tiết kho hàng"
        open={inventoryDetailModal}
        onCancel={() => {
          setInventoryDetailModal(false);
        }}
        footer={false}
      >
        <DetailInventory id={test} />
      </Modal>

      <Modal
        title="Chuyển sản phẩm trong kho"
        open={inventoryChangeProductModal}
        onCancel={() => {
          setInventoryChangeProductModal(false);
        }}
        footer={false}
      >
        <ChangeProductInventory />
      </Modal>
    </>
  );
};

export default Inventory;
