import { createContext, useState } from "react";
import { ThemeProvider } from "styled-components";
import { ThemeDark } from "./theme-dark/base";
import { ThemeLight } from "./theme-light/base";
const ProviderThemes = createContext();
function Themes({ children }) {
  const [theme, setTheme] = useState("light");
  return (
    <ProviderThemes.Provider value={[theme, setTheme]}>
      <ThemeProvider theme={theme === "light" ? ThemeLight :ThemeDark }>
        {children}
      </ThemeProvider>
    </ProviderThemes.Provider>
  );
}
export default Themes;
export {
    ProviderThemes
}