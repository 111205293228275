import { Avatar } from "antd";
import React, { useEffect, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { useDataAll } from "../../../hooks";
import {
  BgAvatar,
  BlockAvatar,
  BoxAvatar,
  ModalChangePassword,
  Container,
  FormInfoUser,
  FormItemInfoUser,
  InputInFoUser,
  SectionAvatar,
  BtnChangePassword,
} from "../style";
import { apiv1 } from "../../../core/data";
import { addUserInformation } from "../../../core/store";
import ChangePassword from "../components/changePassword";
const Profile = () => {
  const [dataAll, setDataAll] = useDataAll();
  const [changePasswordModel, setChangePasswordModel] = useState(false);
  useEffect(() => {
    const profile = async () => {
      const res = await apiv1.get(
        `/profile/${dataAll.dataAccount.metadata.user._id}`,
        dataAll.keyHeader
      );
      setDataAll(addUserInformation(res.data.metadata.profile));
      // console.log(res.data.metadata.profile);
    };
    profile();
  }, []);
  // console.log(dataAll.userInformation);
  return (
    <Container>
      <SectionAvatar>
        <BlockAvatar>
          <BoxAvatar
            style={{
              background: "linear-gradient( 90deg, #001529 0%,#5fffff 100%)",
              borderTopRightRadius: "20px",
            }}
          />
          <BoxAvatar style={{ borderBottomRightRadius: "20px" }}>
            <BgAvatar
              style={{ position: "relative", top: "-44%", marginLeft: "1.5%" }}
            >
              <Avatar size={120} icon={<UserOutlined />} />
            </BgAvatar>
            <div>
              <h2>{dataAll.userInformation.user_fullname}</h2>
              <span>hello myfriends</span>
            </div>
          </BoxAvatar>
          <FormInfoUser>
            <FormItemInfoUser label="Tên người dùng">
              <InputInFoUser
                placeholder={dataAll.userInformation.user_fullname}
              />
            </FormItemInfoUser>
            <FormItemInfoUser label="Tài khoản">
              <InputInFoUser
                placeholder={dataAll.userInformation.user_account}
              />
            </FormItemInfoUser>
            <FormItemInfoUser label="Email">
              <InputInFoUser placeholder={dataAll.userInformation.user_email} />
            </FormItemInfoUser>
            <FormItemInfoUser label="Vai trò ">
              <InputInFoUser placeholder={dataAll.userInformation.user_role} />
            </FormItemInfoUser>
            <FormItemInfoUser label="Ngày tạo">
              <InputInFoUser placeholder={dataAll.userInformation.createdAt} />
            </FormItemInfoUser>
            <FormItemInfoUser label="Ngày cập nhật">
              <InputInFoUser placeholder={dataAll.userInformation.updatedAt} />
            </FormItemInfoUser>
            <FormItemInfoUser>
              <BtnChangePassword
                onClick={() => {
                  setChangePasswordModel(true);
                }}
              >
                Đổi mật khẩu
              </BtnChangePassword>
            </FormItemInfoUser>
          </FormInfoUser>
        </BlockAvatar>
      </SectionAvatar>
      <ModalChangePassword
        title="Đổi mật khẩu"
        width={1000}
        open={changePasswordModel}
        onCancel={() => {
          setChangePasswordModel(false);
        }}
        footer={null}
      >
        <div>
          <ChangePassword />
        </div>
      </ModalChangePassword>
    </Container>
  );
};

export default Profile;
