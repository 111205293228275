import React from "react";
import { useState } from "react";

import { Input, Button, Form } from "antd";
import { apiv1 } from "../../../../core/data";
import { useDataAll } from "../../../../hooks";

const AddSupplier = () => {
  const [dataAll, dispatchDataAll] = useDataAll();
  const [suppliernNameInput, setSupplierNameInput] = useState('');
  const [supplierAddressInput, setSupplierAddressInput] = useState('');
  const [supplierPhoneInput, setSupplierPhoneInput] = useState('')

  const handleSubmit = async () => {
    const supplierData = {
      supplier_name: suppliernNameInput,
      supplier_address: supplierAddressInput,
      supplier_phone: supplierPhoneInput,
    };

    await apiv1.post("/suppliers", supplierData,dataAll.keyHeader);
    
    window.location.reload(false)
  };

  return (
    <>
      <div>
        <Form layout="vertical" onSubmitCapture={handleSubmit}>
          <Form.Item label="Tên nhà cung cấp" required>
            <Input placeholder="Tên nhà cung cấp" name="supplier" value={suppliernNameInput} onChange={(e)=>{setSupplierNameInput(e.target.value)}}/>
          </Form.Item>
          <Form.Item label="Địa chỉ">
            <Input placeholder="Địa chỉ" name="address" value={supplierAddressInput} onChange={(e)=>{setSupplierAddressInput(e.target.value)}}/>
          </Form.Item>
          <Form.Item label="Số điện thoại">
            <Input placeholder="Số điện thoại" name="phone" value={supplierPhoneInput} onChange={(e)=>{setSupplierPhoneInput(e.target.value)}}/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Thêm
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default AddSupplier