import { IMPORT_ORDER_INVENTORY } from "./action";
import { IMPORT_ORDER_QUANTITY } from "./action";
import { IMPORT_ORDER_PRODUCT } from "./action";
import {
  IMPORT_CUSTOMER,
  IMPORT_ORDER_DETAIL,
  IMPORT_ORDER_NAME,
  IMPORT_ORDER_STATUS,
} from "./action";

const initOrderDetail = {
  orderName: "",
  orderStatus: "",
  orderDetail: [],
  customer: "",
  orderProduct:"",
  orderInventory: "",
  orderQuantity: "",
};
const reducerOrderDetail = (state, action) => {
  switch (action.type) {
    case IMPORT_ORDER_NAME:
      return {
        ...state,
        orderName: action.value,
      };
      case IMPORT_ORDER_PRODUCT:
      return {
        ...state,
        orderProduct: action.value,
      };
      case IMPORT_ORDER_INVENTORY:
      return {
        ...state,
        orderInventory: action.value,
      };
      case IMPORT_ORDER_QUANTITY:
      return {
        ...state,
        orderQuantity: action.value,
      };
    case IMPORT_ORDER_STATUS:
      return {
        ...state,
        orderStatus: action.value,
      };
    case IMPORT_CUSTOMER:
      return {
        ...state,
        customer: action.value,
      };
    case IMPORT_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: [action.value, ...state.orderDetail],
      };
    default:
      console.log("error reducer order detail");
      return;
  }
};
export { initOrderDetail, reducerOrderDetail };
