import React, { useState, useEffect } from "react";
import { Input, Button, Form, Select } from "antd";
import { apiv1 } from "../../../../core/data";
import { useDataAll } from "../../../../hooks";
import { CheckStatus } from "../../../../core/status";

const { Option } = Select;
const InventoryProduct = () => {
  const [dataAll, dispatchDataAll] = useDataAll();

  const [productIdInput, setProductIdInput] = useState(null);
  const [inventoryIdInput, setInventoryIdInput] = useState(null);
  const [quantityInput, setQuantityInput] = useState(null);

  const [productData, setProductData] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const res = await apiv1.get("products", dataAll.keyHeader);
        setProductData(res.data.metadata.products);
      } catch (error) {
        const statusCode = error.response.data.code;
        const messageCode = error.response.data.message;
        // CheckStatus(statusCode, messageCode);
      }
    };

    getProducts();
  }, []);

  useEffect(() => {
    const getInventories = async () => {
      try {
        const res = await apiv1.get("inventories", dataAll.keyHeader);
        setInventoryData(res.data.metadata.inventories);
      } catch (error) {
        const statusCode = error.response.data.code;
        const messageCode = error.response.data.message;
        // CheckStatus(statusCode, messageCode);
      }
    };

    getInventories();
  }, []);

  const handleSubmit = async () => {
    const newData = {
      ProductId: productIdInput,
      InventoryId: inventoryIdInput,
      quantity: quantityInput,
    };
    try {
      await apiv1.post("/product_inventories", newData, dataAll.keyHeader);
    } catch (error) {
      const statusCode = error.response.data.code;
      const messageCode = error.response.data.message;
      // CheckStatus(statusCode, messageCode);
    }
  };

  return (
    <>
      <Form layout="vertical" onSubmitCapture={handleSubmit}>
        <Form.Item label="Hàng tồn kho" required>
          <Select
            placeholder="Danh sách sản phẩm"
            name="product"
            value={productIdInput}
            onChange={(value) => {
              setProductIdInput(value);
            }}
          >
            {productData.map((item) => {
              return (
                <>
                  <Option key={item._id} value={item._id}>
                    {item.product_name}
                  </Option>
                </>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Danh sách kho">
          <Select
            placeholder="Danh sách kho"
            name="inventory"
            value={inventoryIdInput}
            onChange={(value) => {
              setInventoryIdInput(value);
            }}
          >
            {inventoryData.map((item) => {
              return (
                <>
                  <Option key={item._id} value={item._id}>
                    {item.inventory_name}
                  </Option>
                </>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Số lượng">
          <Input
            placeholder="Số lượng"
            name="quantity"
            value={quantityInput}
            onChange={(e) => {
              setQuantityInput(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Thêm
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default InventoryProduct;
