import React, { useEffect, useReducer, useState } from "react";

import { apiv1 } from "../../../core/data";
import { useDataAll } from "../../../hooks";
import {
  AddFormOrder,
  BlockFormAddOrder,
  ButtonAddOrder,
  FormItemOrder,
  FormListOrder,
  InputBox,
  InputOrder,
  OptionOrder,
  SelectAddOrder,
} from "./style";
import {
  initOrderDetail,
  reducerOrderDetail,
  setImportCustomer,
  setImportOrderDetail,
  setImportOrderInventory,
  setImportOrderName,
  setImportOrderProduct,
  setImportOrderQuantity,
  setImportOrderStatus,
} from "./store";
import { Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import "./style/index.css";
const AddOrder = () => {
  const [dataAll] = useDataAll();
  const [optionCustomer, setOptionCustomer] = useState([]);
  const [optionProduct, setOptionProduct] = useState([]);
  const [optionInventory, setOptionInventory] = useState([]);
  const [orderDetail, setOrderDetail] = useReducer(
    reducerOrderDetail,
    initOrderDetail
  );
  const [titleWarning, setTitleWarning] = useState(false);
  useEffect(() => {
    const getCustomers = async () => {
      try {
        const res = await apiv1.get("/customers", dataAll.keyHeader);
        // console.log(res.data.metadata.customers);
        setOptionCustomer(res.data.metadata.customers);
      } catch (error) {
        console.log(error.message);
      }
    };
    const getProduct = async () => {
      try {
        const res = await apiv1.get("/products", dataAll.keyHeader);
        // console.log(res.data.metadata.products);
        setOptionProduct(res.data.metadata.products);
      } catch (error) {
        console.log(error.message);
      }
    };
    getProduct();
    getCustomers();
  }, []);
  useEffect(() => {
    const getInventoryProduct = async () => {
      try {
        const res = await apiv1.get(
          `/inventory_by_product/${orderDetail.orderProduct}`,
          dataAll.keyHeader
        );
        const a = res.data.metadata.inventoryByProduct;
        if (a.length > 0) {
          setTest('')
          setOptionInventory(res.data.metadata.inventoryByProduct);
          setTitleWarning(false);
        } else {
          setTitleWarning(true);
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    getInventoryProduct();
  }, [orderDetail.orderProduct]);
  const filterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };
  const onfinish = async (value) => {
    const orderData = {
      order_name: orderDetail.orderName,
      order_status: "pending",
      order_customer: orderDetail.customer,
      order_detail: value.theNumberOfProduct,
    };
    // console.log(value.theNumberOfProduct);
    try {
      await apiv1.post("/orders", orderData, dataAll.keyHeader);
      window.location.reload(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  // console.log(orderDetail.orderProduct);
  // console.log(optionInventory);
  // useEffect(()=>{
  //   optionInventory.map(value=>{
  //     const productPrice = value.Product.product_price
  //   })
  // },[optionInventory])
  const [test, setTest] = useState('')
  console.log(test);
  return (
    <BlockFormAddOrder>
      <AddFormOrder 
        onFinish={onfinish} 
        // autoComplete="off"
      >
        <InputBox>
          <FormItemOrder
            label="Tên Đơn"
            name="orderName"
            rules={[{ required: true, message: "Please input!" }]}
          >
            <InputOrder
              onChange={(e) =>
                setOrderDetail(setImportOrderName(e.target.value))
              }
            />
          </FormItemOrder>
        </InputBox>
        <InputBox>
          <FormItemOrder
            label="Khách Hàng"
            name="customer"
            rules={[{ required: true, message: "Please input!" }]}
          >
            <SelectAddOrder
              showSearch
              onChange={(value, option) => {
                setOrderDetail(setImportCustomer(option.value));
              }}
              filterOption={filterOption}
              options={optionCustomer.map((value) => {
                return {
                  value: value._id,
                  label: value.customer_name,
                };
              })}
            />
          </FormItemOrder>
        </InputBox>
        <InputBox>
          <FormListOrder name="theNumberOfProduct">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    className="theNumberOfProduct"
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: 8,
                      width: "100%",
                    }}
                    align="baseline"
                  >
                    <FormItemOrder
                      {...restField}
                      name={[name, "ProductId"]}
                      rules={[{ required: true, message: "Please input!" }]}
                    >
                      <SelectAddOrder
                        showSearch
                        placeholder="San Pham"
                        onChange={(value, option) => {
                          return setOrderDetail(
                            setImportOrderProduct(option.value)
                          );
                        }}
                        filterOption={filterOption}
                        options={optionProduct.map((value) => {
                          return {
                            value: value._id,
                            label: value.product_name,
                          };
                        })}
                      />
                    </FormItemOrder>
                    <FormItemOrder
                      {...restField}
                      name={[name, "Inventory"]}
                      rules={[{ required: true, message: "Please input!" }]}
                    >
                      <SelectAddOrder
                        showSearch
                        placeholder="kho"
                        onChange={(value, option) => {
                         setTest(option.label)
                        }}
                        filterOption={filterOption}
                        options={optionInventory.map((value) => {
                          const Inventory = value.inventory.inventory_name;
                          const array = {
                            value: value.inventoryId,
                            label: Inventory,
                          };
                          return array;
                        })}
                        disabled={titleWarning}
                      />
                    </FormItemOrder>

                    <FormItemOrder
                      {...restField}
                      name={[name, "quantity"]}
                      rules={[{ required: true, message: "Please input!" }]}
                    >
                      <InputOrder
                        type="number"
                        placeholder="So Luong"
                        // onChange={(e) =>
                        //   setOrderDetail(setImportOrderQuantity(e.target.value))
                        // }
                        disabled={titleWarning}
                      />
                    </FormItemOrder>

                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <FormItemOrder>
                  <ButtonAddOrder
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </ButtonAddOrder>
                </FormItemOrder>
              </>
            )}
          </FormListOrder>
        </InputBox>

        <InputBox>
          <ButtonAddOrder htmlType="submit"> Submit</ButtonAddOrder>
          <ButtonAddOrder htmlType="reset">Reset</ButtonAddOrder>
        </InputBox>
      </AddFormOrder>
    </BlockFormAddOrder>
  );
};

export default AddOrder;
