import { Button } from "antd";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const ExcelExport = ({ productData }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const fileExtension = ".xlsx";

  const exportToCSV = (productDataExcel, fileName) => {
    var wscols = [
      { width: 10 }, // first column
      { width: 20 }, // second column
      { width: 20 }, //...
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    const ws = XLSX.utils.json_to_sheet(productDataExcel);
    ws["!cols"] = wscols;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const productDataExcel = productData.map((product) => ({
    ID: product._id,
    "Tên sản phẩm": product.product_name,
    "Thương hiệu": product.Brand.brand_name,
    "Danh mục": product.Category.category_name,
    "Nhà cung cấp": product.Supplier.supplier_name,
    Kho: product.Inventories[0]?.inventory_name,
    "Giá nhập": product.product_price,
    "Giá bán": product.product_sell_price,
    "Giá sỉ": product.product_wholesale_price,
  }));
  return (
    <>
      <div>
        <Button
          variant="warning"
          onClick={(e) => exportToCSV(productDataExcel, "Sản phẩm")}
        >
          Export
        </Button>
      </div>
    </>
  );
};

export default ExcelExport;
