import React, { useState, useEffect, useReducer } from "react";
import { Table, Space, Tooltip, Modal, Button, Popconfirm, Flex } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

import { FilterOutlined } from "@ant-design/icons";

import { Input, Form } from "antd";

import { apiv1 } from "../../../core/data";

import AddSupplier from "../components/sub-page/AddSupplier";
import { useDataAll } from "../../../hooks";

const Supplier = () => {
  const [dataAll, dispatchDataAll] = useDataAll();
  const [supplierData, setSupplierData] = useState([]);
  
  const [open, setOpen] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  
  const [loading,setLoading] = useState(false);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  const [suppliernNameInput, setSupplierNameInput] = useState("");
  const [supplierAddressInput, setSupplierAddressInput] = useState("");
  const [supplierPhoneInput, setSupplierPhoneInput] = useState("");
  const [supplierIdInput, setSupplierIdInput] = useState(null);

  const handleFormUpdate = (item) => {
    setUpdateModal(true);
    setSupplierIdInput(item._id);
    setSupplierNameInput(item.supplier_name);
    setSupplierAddressInput(item.supplier_address);
    setSupplierPhoneInput(item.supplier_phone);
  };

  const handleFormUpdateSubmit = async (id) => {
    const supplierData = {
      supplier_name: suppliernNameInput,
      supplier_address: supplierAddressInput,
      supplier_phone: supplierPhoneInput
    }

    await apiv1.patch(`suppliers/update/${id}`,supplierData,dataAll.keyHeader);
    window.location.reload(false)
  }

  useEffect(() => {
    setLoading(true)
    const getSupplier = async () => {
      try {
        const res = await apiv1.get("/suppliers", dataAll.keyHeader);
        setSupplierData(res.data.metadata.suppliers);
        setLoading(false)
      } catch (error) {
        console.log(error.message);
      }
    };

    getSupplier();
  }, [reducerValue]);

  const deleteSupplier = async (id) => {
    await apiv1.delete(`/suppliers/delete/${id}`, dataAll.keyHeader);
    forceUpdate();
  };

  return (
    <>
      <div>
        <Flex align="center" justify="space-between">
          <div>
            <Space>
              <Tooltip title="Thêm nhà cung cấp">
                <Button
                  type="primary"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  +
                </Button>
              </Tooltip>
              <Button
                icon={<ReloadOutlined />}
                onClick={() => window.location.reload(false)}
              >
                Tải lại
              </Button>
            </Space>
          </div>
          <div>
            <FilterOutlined size="large" onClick={()=>{alert("cba")}}/>
          </div>
        </Flex>
      </div>
      <div style={{ marginTop: "15px" }}>
        <Table
          loading = {loading}
          bordered
          dataSource={supplierData}
          columns={[
            {
              title: "Mã nhà cung cấp",
              dataIndex: "_id",
              key: "_id",
            },
            {
              title: "Tên nhà cung cấp",
              dataIndex: "supplier_name",
              key: "supplier_name",
            },
            {
              title: "Địa chỉ",
              dataIndex: "supplier_address",
              key: "supplier_address",
            },
            {
              title: "Số điện thoại",
              dataIndex: "supplier_phone",
              key: "supplier_phone",
            },
            {
              title: "Hành động",
              render: (item) => {
                return (
                  <>
                    <Space>
                      <Button
                        onClick={() => {
                          handleFormUpdate(item);
                        }}
                      >
                        Sửa
                      </Button>
                      <Popconfirm
                        title="Xóa nhà cung cấp"
                        onConfirm={() => {
                          deleteSupplier(item._id);
                        }}
                      >
                        <Button danger>Xóa</Button>
                      </Popconfirm>
                    </Space>
                  </>
                );
              },
            },
          ]}
        ></Table>
      </div>

      <Modal
        title="Thêm nhà cung cấp"
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        footer={false}
      >
        <AddSupplier />
      </Modal>

      <Modal
        title="Chỉnh sửa nhà cung cấp"
        open={updateModal}
        onCancel={() => {
          setUpdateModal(false);
        }}
        footer={false}
      >
        <>
          <div>
            <Form layout="vertical">
              <Form.Item label="Tên nhà cung cấp" required>
                <Input
                  placeholder="Tên nhà cung cấp"
                  name="supplier"
                  value={suppliernNameInput}
                  onChange={(e) => {setSupplierNameInput(e.target.value)}}
                />
              </Form.Item>
              <Form.Item label="Địa chỉ">
                <Input placeholder="Địa chỉ" 
                  name="address"
                  value={supplierAddressInput}
                  onChange={(e) => {setSupplierAddressInput(e.target.value)}} 
                />
              </Form.Item>
              <Form.Item label="Số điện thoại">
                <Input placeholder="Số điện thoại" 
                  name="phone" 
                  value={supplierPhoneInput}
                  onChange={(e) => {setSupplierPhoneInput(e.target.value)}}
                  />
              </Form.Item>
              <Form.Item>
                <Space>
                  <Popconfirm 
                    title="Xác nhận thay đổi ?"
                    onConfirm={()=>{handleFormUpdateSubmit(supplierIdInput)}}
                    >
                    <Button type="primary" htmlType="submit">
                      Sửa
                    </Button>
                  </Popconfirm>
                  <Button onClick={()=>{setUpdateModal(false)}}>Hủy</Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
        </>
      </Modal>
    </>
  );
};

export default Supplier;