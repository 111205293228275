import styled from "styled-components";
import { Form, Input, Button, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import FormList from "antd/es/form/FormList";
const BlockFormAddOrder = styled.div`
  width: 100%;
  height: 100%;
`;
const AddFormOrder = styled(Form)`
  width: 100%;
  height: 100%;
`;
const BoxTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content:space-around;
  align-items:center;
  width: 100%;
`
const InputBox = styled.div``;
const FormItemOrder = styled(FormItem)``
const InputOrder = styled(Input)``;
const ButtonAddOrder = styled(Button)``;
const SelectAddOrder = styled(Select)``;
const OptionOrder = styled(Select.Option)``;
const FormListOrder = styled(FormList)``;
export {
  AddFormOrder,
  InputOrder,
  ButtonAddOrder,
  BlockFormAddOrder,
  InputBox,
  FormItemOrder,
  SelectAddOrder,
  OptionOrder,
  FormListOrder,
  BoxTable
};
