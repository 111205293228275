import styled from "styled-components";
import { Button, Form, Input, Modal } from "antd";
import FormItem from "antd/es/form/FormItem";
const Container = styled.div`
  width: 100%;
  height: 100%;
`;
const SectionAvatar = styled.div`
  width: 100%;
  height: 25%;
`;
const BlockAvatar = styled.div`
  width: 100%;
  height: 100%;
`;
const BoxAvatar = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  column-gap: 20px;
`;
const BgAvatar = styled.div`
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colorDeco7};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1.5px 1.5px 1.5px ${({ theme }) => theme.colorDeco22};
`;
const FormInfoUser = styled(Form)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 20%;
`;
const FormItemInfoUser = styled(FormItem)`
  width: 40%;
  &::placeholder {
    color: ${({ theme }) => theme.colorDeco21};
  }
`;
const InputInFoUser = styled(Input)`
  width: 100%;
  height: 100%;
`;
const ModalChangePassword = styled(Modal)`
  width: 100%;
  height: 100%;
`;
const BtnChangePassword = styled(Button)`
  width: 100%;
  height: 100%;
`;
export * from "./changePassword.css";
export {
  Container,
  SectionAvatar,
  BlockAvatar,
  BoxAvatar,
  BgAvatar,
  FormInfoUser,
  InputInFoUser,
  FormItemInfoUser,
  ModalChangePassword,
  BtnChangePassword,
};
