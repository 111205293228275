import { SHOW_ADD_STAFF } from "./action";

const initShow = {
    showAddStaff:false,
};
const reducerShow = (state, action) => {
    switch (action.type) {
        case SHOW_ADD_STAFF:
            return {
                ...state,
                showAddStaff: action.value,
            }
        default:
            throw new Error ("Invalid reducer show")
    }
};
export { initShow, reducerShow };
