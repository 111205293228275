import { Table } from "antd";
import { useEffect, useState } from "react";
import { apiv1 } from "../../../../core/data";
import { useDataAll } from "../../../../hooks";

const DetailInventory = ({ id }) => {
  const [dataAll, dispatchDataAll] = useDataAll();

  const [detailInventoryData, setDetailInventoryData] = useState([]);

  useEffect(() => {
    const getDetailInventory = async () => {
      try {
        const res = await apiv1.get(
          `product_by_inventory/${id}`,
          dataAll.keyHeader
        );
        setDetailInventoryData(res.data.metadata.productsByInventory);
        console.log(res.data.metadata.productsByInventory);
      } catch (error) {
        console.log(error);
      }
    };

    getDetailInventory();
  }, [id]);

  return (
    <>
      <div style={{ marginTop: "15px" }}>
        <Table
          bordered
          dataSource={detailInventoryData}
          columns={[
            {
              title: "Mã sản phẩm",
              dataIndex: "product.product_id",
              key: "product.product_id",
            },
            {
              title: "Tên sản phẩm",
              dataIndex: "product.product_name",
              key: "product.product_name",
            },
            {
              title: "Số lượng",
              dataIndex: "quantity",
              key:"quantity"
            },
          ]}
        ></Table>
      </div>
    </>
  );
};

export default DetailInventory;
