import { path } from "../../routers/path"
import Supplier from "./page"
import DefaultLayout from "../../components/layouts/Default"
const routers = [
    {
        path:path.suppliers,
        page:Supplier,
        layout:DefaultLayout,
    }
]
export default routers