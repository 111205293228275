import { ADD_ACCESS_TOKEN, ADD_USER_INFORMATION } from "./action";
import { ADD_ID_USER } from "./action";
import { ADD_DATA_ACCOUNT } from "./action";
const StorageAccount = JSON.parse(localStorage.getItem("data_account"));
const StorageKeyHeader = JSON.parse(localStorage.getItem("keyheader"));
const initDataAll = {
  dataAccount: StorageAccount ?? {},
  keyHeader: StorageKeyHeader ?? {
    headers: {
      "x-a": "234",
      "x-client-id": "",
      Authorization: "",
    },
  },
  userInformation: {},
};
const reducerDataAll = (state, action) => {
  switch (action.type) {
    case ADD_DATA_ACCOUNT:
      const dataAccount = {
        ...state,
        dataAccount: action.value,
      };
      return dataAccount;
    case ADD_USER_INFORMATION:
      const addUserInformation = {
        ...state,
        userInformation: action.value,
      };
      return addUserInformation;
    case ADD_ID_USER:
      // console.log(state.keyHeader.headers);
      const dataUserId = {
        ...state,
        keyHeader: {
          headers: {
            ...state.keyHeader.headers,
            "x-client-id": action.value.toString(),
          },
        },
      };
      return dataUserId;

    case ADD_ACCESS_TOKEN:
      const dataAccessToken = {
        ...state,
        keyHeader: {
          headers: {
            ...state.keyHeader.headers,
            Authorization: action.value,
          },
        },
      };
      console.log(dataAccessToken);
      const JsonStrAccount = JSON.stringify(dataAccessToken.dataAccount);
      const JsonStrkeyHeader = JSON.stringify(dataAccessToken.keyHeader);
      localStorage.setItem("data_account", JsonStrAccount);
      localStorage.setItem("keyheader", JsonStrkeyHeader);
      return dataAccessToken;

    default:
      console.log("error reducer");
      return;
  }
};
export { initDataAll, reducerDataAll };
