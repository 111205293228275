import { path } from "../../routers/path"
import DefaultLayout from "../../components/layouts/Default"
import Customer from "./page"
const routers = [
    {
        path:path.customers,
        page:Customer,
        layout:DefaultLayout,
    }
]
export default routers