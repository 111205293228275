import React, { useState } from "react";
import { Input, Button, Form } from "antd";

import { apiv1 } from "../../../../core/data";
import { useDataAll } from "../../../../hooks";
import { CheckStatus } from "../../../../core/status";

const AddInventory = () => {
  const [dataAll, dispatchDataAll] = useDataAll();
  const [inventory_name, setInventoryName] = useState("");
  const [inventory_address, setInventoryAddress] = useState("");
  const [inventory_phone, setInventoryPhone] = useState("");
  const handleSubmit = async () => {
    const dataInventory = {
      inventory_name: inventory_name,
      inventory_address: inventory_address,
      inventory_phone: inventory_phone,
    };
    try {
      await apiv1.post("/inventories", dataInventory, dataAll.keyHeader);
    } catch (error) {
      const statusCode = error.response.data.code;
      const messageCode = error.response.data.message;
      // CheckStatus(statusCode, messageCode);
    }
  };

  return (
    <>
      <Form layout="vertical" onSubmitCapture={handleSubmit}>
        <Form.Item label="Hàng tồn kho" required>
          <Input
            placeholder="Hàng tồn kho"
            name="supplier"
            value={inventory_name}
            onChange={(e) => {
              setInventoryName(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Địa chỉ">
          <Input
            placeholder="Địa chỉ"
            name="address"
            value={inventory_address}
            onChange={(e) => {
              setInventoryAddress(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Số điện thoại">
          <Input
            placeholder="Số điện thoại"
            name="phone"
            value={inventory_phone}
            onChange={(e) => {
              setInventoryPhone(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Thêm
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddInventory;
