import React from "react";
import { useEffect, useReducer, useState, useMemo } from "react";



import {
  Input,
  Typography,
  Flex,
  Button,
  Form,
  notification,
  List,
  Popconfirm,
} from "antd";
import { apiv1 } from "../../../../core/data";
import { useDataAll } from "../../../../hooks";

const { Search } = Input;
const { Text } = Typography;

const Context = React.createContext({
  name: "Default",
});

const Category = () => {
  const [dataAll, dispatchDataAll] = useDataAll();

  const [categoryData, setCategoryData] = useState([]);
  const [addCategoryState, setAddCategoryState] = useState(false);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openAddCategory = () => {
    setAddCategoryState(true);
    setIsOpen(true);
  };

  const closeAddCategory = () => {
    setAddCategoryState(false);
    setIsOpen(false);
  };

  const [data, setData] = useState({
    category: "",
  });

  const handleChange = (e) => {
    setData({
      category: e.target.value,
    });
  };

  const handleSubmit = async () => {
    const categoryData = {
      category_name: data.category,
    };
    await apiv1.post("/categories", categoryData, dataAll.keyHeader);
    console.log(dataAll.keyHeader);
    setData({ category: "" });
    forceUpdate();
    openNotification("bottomRight");
  };

  const [countCategories, setCountCategories] = useState(0);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const res = await apiv1.get("/categories",dataAll.keyHeader);
        setCountCategories(res.data.metadata.totalCategories);
        setCategoryData(res.data.metadata.categories);
        setFilteredCategories(res.data.metadata.categories);
      } catch (error) {
        console.log(error.message);
      }
    };

    getCategories();
  }, [reducerValue]);

  const deleteCategory = async (id, e) => {
    await apiv1.delete(`/categories/delete/${id}`,dataAll.keyHeader);
    setIsLoading(false);
    forceUpdate();
    openNotification("bottomRight");
  };

  const [searchItem, setSearchItem] = useState("");
  const [filteredCategories, setFilteredCategories] = useState([]);
  const handleInputChange = (e) => {
    const searchItem = e.target.value;
    setSearchItem(searchItem);

    const filteredItems = categoryData.filter((category) => {
      return category.category_name
        .toLowerCase()
        .includes(searchItem.toLowerCase());
    });

    setFilteredCategories(filteredItems);
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement) => {
    api.success({
      message: "Thêm thành công",
      description: (
        <Context.Consumer>{() => `Thêm thành công`}</Context.Consumer>
      ),
      placement,
    });
  };

  const contextValue = useMemo(
    () => ({
      name: "aaa",
    }),
    []
  );

  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <Search
          size="large"
          placeholder="Tìm danh mục"
          value={searchItem}
          onChange={handleInputChange}
          enterButton
        />
      </div>
      <div style={{ margin: "20px 0" }}>
        <Flex align="center" justify="space-between">
          <Text strong>Tổng danh mục: {countCategories}</Text>
          <Button type="primary" onClick={openAddCategory} disabled={isOpen}>
            Thêm
          </Button>
        </Flex>
      </div>
      {addCategoryState && (
        <div
          style={{
            margin: "20px 0",
            border: "#00ffff solid 1px",
            padding: "10px 20px",
            borderRadius: "6px",
            backgroundColor: "#ededed",
          }}
        >
          <Form layout="inline" onSubmitCapture={handleSubmit}>
            <Form.Item>
              <Input
                placeholder="Danh mục"
                name="category"
                value={data.category}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item>
              <Button htmlType="button" onClick={closeAddCategory}>
                Hủy
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Lưu
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
      <div>
        <List
          loading={isLoading}
          dataSource={filteredCategories}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button>Sửa</Button>,
                <Popconfirm
                  title="Xóa danh mục"
                  onConfirm={()=>{deleteCategory(item._id)}}
                >
                  <Button danger>Xóa</Button>
                </Popconfirm>,
              ]}
            >
              {item.category_name}
            </List.Item>
          )}
        />
      </div>
      <Context.Provider value={contextValue}>{contextHolder}</Context.Provider>
    </>
  );
};
export default Category;