import { Button, Flex, Modal, Space, Table, Tooltip } from "antd";
import styled from "styled-components";
const SectionManagerStaff = styled.section``;
const BlockManagerStaff = styled.div``;
const BoxManagerStaff = styled.div``;
const FlexManagerStaff = styled(Flex)``;
const SpaceManagerStaff = styled(Space)``;
const TooltipManagerStaff = styled(Tooltip)``;
const BtnManagerStaff = styled(Button)``;
const ModalManagerStaff = styled(Modal)``;
const TableManagerStaff = styled(Table)``;
export * from "./addStaff"
export {
  SectionManagerStaff,
  BlockManagerStaff,
  FlexManagerStaff,
  SpaceManagerStaff,
  TooltipManagerStaff,
  BoxManagerStaff,
  BtnManagerStaff,
  ModalManagerStaff,
  TableManagerStaff
};
