import DefaultLayout from "../../components/layouts/Default";
import { path } from "../../routers/path";
import Config from "./page";

const router = [
    {
        path:path.config,
        page:Config,
        layout:DefaultLayout,
        exact:true,
    }
]
export default router