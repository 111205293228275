import login from "../module/login/router";
import inventory from "../module/inventory/router";
import product from "../module/product/router";
import supplier from "../module/supplier/router";
import dashboard from "../module/home/router";
import notfound from "../module/notFound/router";
import customer from "../module/customer/router";
import forgotPassword from "../module/forgotPassword/router";
import profile from "../module/profile/router";
import order from "../module/order/router";
import brand from "../module/brand/router";
import config from "../module/config/router";
import managerStaff from "../module/managerStaff/router"
const rootRouter = [
  ...login,
  ...inventory,
  ...product,
  ...supplier,
  ...dashboard,
  ...notfound,
  ...customer,
  ...order,
  ...profile,
  ...forgotPassword,
  ...brand,
  ...config,
  ...managerStaff,
];
export { rootRouter };
