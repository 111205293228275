import React from "react";

import { Button, Form, Upload } from "antd";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { useDataAll } from "../../../../hooks";

const props = {
  name: 'excelFile',
  action: "http://localhost:3001/v1/api/upload",
  headers: {
    authorization: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsInVzZXJuYW1lIjoiYWRtaW4iLCJpYXQiOjE3MTQwMTAzNDAsImV4cCI6MTcxNDE4MzE0MH0.gC9EaWKE_PaEO91Ies0BV1R5CIjuYJtcjYYWdlUpIHs",
    'x-client-id': 1
  }
}
const ExcelImport = () => {
  const [dataAll, dispatchDataAll] = useDataAll();
  return (
    <>
      <div style={{ "marginTop": "15px" }}>
        <Upload name="excelFile" action="https://hcmanager.tech/v1/api/upload" headers={dataAll.keyHeader.headers}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </div>
    </>
  );
};

export default ExcelImport;
