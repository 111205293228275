import React, { useEffect, useReducer, useState } from "react";

import AddCustomer from "../components/AddCustomer";
import {
  Table,
  Space,
  Tooltip,
  Modal,
  Button,
  Popconfirm,
  Form,
  Input,
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";

import { useDataAll } from "../../../hooks";
import { apiv1 } from "../../../core/data";
import { CheckStatus } from "../../../core/status";

const Customer = () => {
  const [dataAll, dispatchDataAll] = useDataAll();
  const [customerData, setCustomerData] = useState([]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  const [productUpdateModal, setProductUpdateModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [customerIdInput, setCustomerIdInput] = useState("");
  const [customerNameInput, setCustomerNameInput] = useState("");
  const [customerGenderInput, setCustomerGenderInput] = useState("");
  const [customerAddressInput, setCustomerAddressInput] = useState("");
  const [customerPhoneInput, setCustomerPhoneInput] = useState(null);
  const [customerEmailInput, setCustomerEmailInput] = useState("");

  const handleFormUpdate = (item) => {
    setProductUpdateModal(true);
    setCustomerIdInput(item._id);
    setCustomerNameInput(item.customer_name);
    setCustomerGenderInput(item.customer_gender);
    setCustomerAddressInput(item.customer_address);
    setCustomerPhoneInput(item.customer_phone);
    setCustomerEmailInput(item.customer_email);
  };

  const handleFormUpdateSubmit = async (id) => {
    const dataCustomer = {
      customer_name: customerNameInput,
      customer_gender: customerGenderInput,
      customer_address: customerAddressInput,
      customer_phone: customerPhoneInput,
      customer_email: customerEmailInput,
    };
    try {
      await apiv1.patch(
        `/customers/update/${id}`,
        dataCustomer,
        dataAll.keyHeader
      );
    } catch (err) {
      const statusCode = err.response.data.code;
      const messageCode = err.response.data.message;
      // CheckStatus(statusCode, messageCode);
    }

    window.location.reload(false);
  };

  useEffect(() => {
    setLoading(true);
    const getCustomers = async () => {
      try {
        const res = await apiv1.get("/customers", dataAll.keyHeader);
        console.log(res.data.metadata.customers);
        setCustomerData(res.data.metadata.customers);
        setLoading(false);
      } catch (error) {
        const statusCode = error.response.data.code;
        const messageCode = error.response.data.message;
        // CheckStatus(statusCode, messageCode);
      }
    };

    getCustomers();
  }, [reducerValue]);

  const deleteCustomer = async (id) => {
    try {
      await apiv1.delete(`/customers/delete/${id}`, dataAll.keyHeader);
      forceUpdate();
    } catch (error) {
      const statusCode = error.response.data.code;
      const messageCode = error.response.data.message;
      // CheckStatus(statusCode, messageCode);
    }
  };

  return (
    <>
      <div>
        <Space>
          <Tooltip title="Thêm khách hàng">
            <Button
              type="primary"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              +
            </Button>
          </Tooltip>
          <Tooltip>
            <Button
              icon={<ReloadOutlined />}
              onClick={() => {
                window.location.reload(false);
              }}
            >
              Tải lại
            </Button>
          </Tooltip>
        </Space>
      </div>
      <div style={{ marginTop: "15px" }}>
        <Table
          loading={loading}
          bordered
          dataSource={customerData}
          columns={[
            {
              title: "Mã khách hàng",
              dataIndex: "_id",
              key: "_id",
            },
            {
              title: "Tên khách hàng",
              dataIndex: "customer_name",
              key: "customer_name",
            },
            {
              title: "Giới tính",
              dataIndex: "customer_gender",
              key: "customer_gender",
            },
            {
              title: "Địa chỉ",
              dataIndex: "customer_address",
              key: "customer_address",
            },
            {
              title: "Số điện thoại",
              dataIndex: "customer_phone",
              key: "customer_phone",
            },
            {
              title: "Email",
              dataIndex: "customer_email",
              key: "customer_email",
            },
            {
              title: "Điểm",
              dataIndex: "customer_points",
              key: "customer_points",
            },
            {
              title: "Hành động",
              render: (item) => {
                return (
                  <>
                    <Space>
                      <Button
                        onClick={() => {
                          handleFormUpdate(item);
                        }}
                      >
                        Sửa
                      </Button>
                      <Popconfirm
                        title="Xóa kho"
                        onConfirm={() => {
                          deleteCustomer(item._id);
                        }}
                      >
                        <Button danger>Xóa</Button>
                      </Popconfirm>
                    </Space>
                  </>
                );
              },
            },
          ]}
        />
      </div>

      <Modal
        title="Thêm khách hàng"
        open={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
        footer={false}
      >
        <AddCustomer />
      </Modal>

      <Modal
        title="Sửa thông tin khách hàng"
        open={productUpdateModal}
        onCancel={() => {
          setProductUpdateModal(false);
        }}
        footer={false}
      >
        <>
          <Form layout="vertical">
            <Form.Item label="Tên khách hàng" required>
              <Input
                placeholder="Tên khách hàng"
                name="name"
                value={customerNameInput}
                onChange={(e) => {
                  setCustomerNameInput(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item label="Giới tính">
              <Input
                placeholder="Giới tinh"
                name="gender"
                value={customerGenderInput}
                onChange={(e) => {
                  setCustomerGenderInput(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item label="Địa chỉ">
              <Input
                placeholder="Địa chỉ"
                name="address"
                value={customerAddressInput}
                onChange={(e) => {
                  setCustomerAddressInput(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item label="Số điện thoại">
              <Input
                placeholder="Số điện thoại"
                name="phone"
                value={customerPhoneInput}
                onChange={(e) => {
                  setCustomerPhoneInput(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item label="Email">
              <Input
                placeholder="Email"
                name="email"
                value={customerEmailInput}
                onChange={(e) => {
                  setCustomerEmailInput(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item>
              <Popconfirm
                title="Xác nhận thay đổi ?"
                onConfirm={() => {
                  handleFormUpdateSubmit(customerIdInput);
                }}
              >
                <Button type="primary" htmlType="submit">
                  Sửa
                </Button>
              </Popconfirm>
            </Form.Item>
          </Form>
        </>
      </Modal>
    </>
  );
};

export default Customer;
