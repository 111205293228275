const IMPORT_ORDER_NAME = 'import_order_name';
const IMPORT_ORDER_STATUS = 'import_order_status';
const IMPORT_ORDER_DETAIL= 'import_order_detail';
const IMPORT_CUSTOMER = 'import_customer';
const IMPORT_ORDER_PRODUCT = 'import_order_product';
const IMPORT_ORDER_INVENTORY = 'import_order_inventory';
const IMPORT_ORDER_QUANTITY = 'import_order_quantity';
export{
    IMPORT_ORDER_NAME,
    IMPORT_ORDER_STATUS,
    IMPORT_ORDER_DETAIL,
    IMPORT_CUSTOMER,
    IMPORT_ORDER_PRODUCT,
    IMPORT_ORDER_INVENTORY,
    IMPORT_ORDER_QUANTITY
}