import { useReducer, useState } from "react";
import {
  FormChangePassword,
  FormItemChangePassword,
  InputChangePassword,
  SectionChangePassword,
  Submit,
} from "../style";
import { initChangePassword, reducerChange } from "../store/reducer";
import {
  setConfirmPassword,
  setNewPassword,
  setOldPassword,
} from "../store/constant";
import { useDataAll } from "../../../hooks";
import { HandleApi, apiv1 } from "../../../core/data";

const ChangePassword = () => {
  const [dataAll, setDataAll] = useDataAll();
  const [changePassword, setChangePassword] = useReducer(
    reducerChange,
    initChangePassword
  );
  const HandleChangePassword = async () => {
    if (changePassword.oldPassword !== changePassword.newPassword) {
      if (changePassword.confirmPassword !== changePassword.newPassword) {
        alert("Xác nhận mật khẩu bị sai ");
        setChangePassword(setNewPassword(""));
        setChangePassword(setConfirmPassword(""));
      } else {
        const variableChangePassword = {
          oldPassword: changePassword.oldPassword,
          newPassword: changePassword.newPassword,
        };
        const apipatch = apiv1.patch("/change-password",variableChangePassword,dataAll.keyHeader)
        HandleApi(apipatch)
        setChangePassword(setOldPassword(""));
        setChangePassword(setNewPassword(""));
        setChangePassword(setConfirmPassword(""));
      }
    } else {
      alert("Mật khẩu cũ và mật khẩu mới bị chùng nhau");
      setChangePassword(setOldPassword(""));
      setChangePassword(setNewPassword(""));
      setChangePassword(setConfirmPassword(""));
    }
  };
  return (
    <SectionChangePassword>
      <FormChangePassword onSubmitCapture={() => HandleChangePassword()}>
        <FormItemChangePassword htmlFor="oldPassword" label="Mật khẩu cũ">
          <InputChangePassword
            id="oldPassword"
            onChange={(e) => setChangePassword(setOldPassword(e.target.value))}
            value={changePassword.oldPassword}
          />
        </FormItemChangePassword>
        <FormItemChangePassword htmlFor="newPassword" label="Mật khẩu mới">
          <InputChangePassword
            id="newPassword"
            onChange={(e) => setChangePassword(setNewPassword(e.target.value))}
            value={changePassword.newPassword}
          />
        </FormItemChangePassword>
        <FormItemChangePassword
          htmlFor="confirmPassword"
          label="Xác nhận mật khẩu"
        >
          <InputChangePassword
            id="confirmPassword"
            onChange={(e) =>
              setChangePassword(setConfirmPassword(e.target.value))
            }
            value={changePassword.confirmPassword}
          />
        </FormItemChangePassword>
        <FormItemChangePassword>
          <Submit htmlType="submit">Submit</Submit>
        </FormItemChangePassword>
      </FormChangePassword>
    </SectionChangePassword>
  );
};
export default ChangePassword;
