import styled, { keyframes } from "styled-components";

const FormLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: url("https://unblast.com/wp-content/uploads/2021/01/Space-Background-Images-1536x1024.jpg");
`;
const FormBox = styled.div`
  width: 90%;
  height: 15%;
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #333;
  border-radius: 20px;
  margin-bottom: 5%;
`;
const TitleLogin = styled.h1`
  width: 100%;
  text-align: center;
`;

const InputLogin = styled.input`
  width: 80%;
  height: 80%;
  border: none;
  outline: none;
  background: transparent;
`;
const LabelLogin = styled.label`
  position: absolute;
  left: 11%;
  transform: translateY(50%);
  top: -4.5%;
  transition: 0.5s;
  ${InputLogin}:focus~& {
    position: absolute;
    transition: 0.5s;
    top: -51%;
    background-color: ${({ theme }) => theme.colorDeco1};
  }
  ${InputLogin}:not(:placeholder-shown)+& {
    position: absolute;
    transition: 0.5s;
    top: -51%;
    background-color: #fff;
  }
`;
const warning = keyframes`
0%{
  transition: 0.5s;
  width:0;
};
40%{
  transition: 0.5s;
  width:11%;
};
60%{
  transition: 0.5s;
  width:22%;
};
80%{
  transition: 0.5s;
  width:11%;
};
100%{
  transition: 0.5s;
  width:0;
};
`;
const WarningBlock = styled.div`
  height: 15%;
  position: absolute;
  top: 80%;
  right: 0;
  background-color: #fff;
  display: ${(props) => props.propDisplay};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  font-size: 20px;
  transition: 0.5;
  animation: ${warning} 4s;
`;
export { FormLogin, FormBox, InputLogin, LabelLogin, TitleLogin, WarningBlock };
