import { Table } from "antd";
import { useEffect, useState } from "react";
import { useDataAll } from "../../../hooks";
import { apiv1 } from "../../../core/data";
import formatCurrency from "../../../utils/formatCurrency";
const OrderDetail = ({ id }) => {
  const [dataAll, dispatchDataAll] = useDataAll();
  const [dataOrderDetail, setDataOrderDetail] = useState([]);
  const [dataTotalOrderDetail, setDataTotalOrderDetail] = useState("");
  useEffect(() => {
    console.log(id);
    const callApiOrderDetail = async () => {
      const resOrderDetail = await apiv1.get(
        `/order/detail/${id}`,
        dataAll.keyHeader
      );
      setDataOrderDetail(resOrderDetail.data.metadata.data);
    };
    const totalOrderDetail = async () => {
      try {
        const res = await apiv1.get(`/order/${id}`, dataAll.keyHeader);
        // console.log(res.data.metadata.data[0].order.order_total);
        setDataTotalOrderDetail(res.data.metadata.data[0].order.order_total);
      } catch (err) {
        console.log(err);
      }
    };
    callApiOrderDetail();
    totalOrderDetail();
  }, [id]);
  return (
    <div style={{ marginTop: "15px" }}>
      <Table
        bordered
        dataSource={dataOrderDetail}
        pagination={false}
        footer={() => {
          return (
            <h3>
              Giá trị đơn hàng :{" "}
              <span style={{ color: "blue" }}>
                {formatCurrency(dataTotalOrderDetail)}
              </span>
            </h3>
          );
        }}
        columns={[
          {
            title: "Mã đơn hàng",
            dataIndex: "orderId",
            key: "orderId",
          },
          {
            title: "Mã sản phẩm",
            dataIndex: "productId",
            key: "productId",
          },
          {
            title: "Tên sản phẩm",
            dataIndex: ["product", "product_name"],
            key: ["product", "product_name"],
          },
          {
            title: "Giá sản phẩm",
            dataIndex: ["product", "product_price"],
            key: ["product", "product_price"],
          },
          {
            title: "Số lượng",
            dataIndex: "quantity",
            key: "quantity",
          },
        ]}
      ></Table>
    </div>
  );
};
export default OrderDetail;
