import DefaultLayout from "../../components/layouts/Default";
import { path } from "../../routers/path";
import ManagerStaff from "./page";

const router = [
    {
        path:path.managerStaff,
        page:ManagerStaff,
        layout:DefaultLayout,
        exact:true,
    }
]
export default router