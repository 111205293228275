const path = {
  dashboard: "/dashboard",
  register: "/register",
  products: "/products",
  suppliers: "/suppliers",
  inventories: "/inventories",
  customers: "/customers",
  orders:"/orders",
  profile:"/profile",
  brand:"/brand",
  forgotPassword: "/forgotPassword",
  config:"/setting/config",
  managerStaff:"/setting/manager_staff",
  notfound: "*",
  login: "/",
};
export { path };
