import { path } from "../../routers/path"
import Inventory from "./page"
import DefaultLayout from "../../components/layouts/Default"
const routers = [
    {
        path:path.inventories,
        page:Inventory,
        layout:DefaultLayout,
        exact:true
    }
]
export default routers