import { Button, Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import styled from "styled-components";
const SectionChangePassword = styled.section`
  width: 100%;
`;
const FormChangePassword = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const FormItemChangePassword = styled(FormItem)`
  width: 60%;
`;
const InputChangePassword = styled(Input)`
  width: 100%;
`;
const Submit = styled(Button)`
  width: 50%;
`;export {
  SectionChangePassword,
  FormChangePassword,
  FormItemChangePassword,
  InputChangePassword,
  Submit,
};
