import { Button, Form } from "antd";
import {
  LockOutlined,
  UserOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import {
  FormBox,
  InputLogin,
  LabelLogin,
  TitleLogin,
  WarningBlock,
} from "../style";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiv1 } from "../../../core/data";
import { useDataAll } from "../../../hooks";
import {
  addAccessToken,
  addDataAccount,
  addIdUser,
} from "../../../core/store/constant";
import { path } from "../../../routers/path";
import { FormLogin } from "../style";
import { CheckStatus } from "../../../core/status";
const Login = () => {
  const navigate = useNavigate();
  const [dataAll, dispatchDataAll] = useDataAll();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [err403, setErr403] = useState("");
  const [err401, setErr401] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [timeTest, setTimeTest] = useState(false);
  useEffect(() => {
    if (err403 || err401) {
      const warning = () => {
        setTimeTest(!timeTest);
        const setTimeWarning = setTimeout(() => {
          setTimeTest(false);
        }, 3000);
        return () => clearTimeout(setTimeWarning);
      };
      warning();
    }
  }, [err403, err401]);
  const Submit = async () => {
    const Account = {
      username: username,
      password: password,
    };
    try {
      const apiAccount = await apiv1.post("/login", Account);
      const dataAccount = apiAccount.data;
      dispatchDataAll(addDataAccount(dataAccount));
      dispatchDataAll(addIdUser(dataAccount.metadata.user._id));
      dispatchDataAll(addAccessToken(dataAccount.metadata.tokens.accessToken));
      navigate("/dashboard");
    } catch (err) {
      const statusCode = err.response.data.code;
      const messageCode = err.response.data.message;
      // CheckStatus(
      //   statusCode,
      //   messageCode,
      //   navigate,
      //   setErr403,
      //   setErr401,
      //   setUsername,
      //   setPassword
      // );
      if (statusCode === 403) {
        setErr403(messageCode);
        navigate("/");
        setUsername("");
        setPassword("");
      }
      if (statusCode === 401) {
        setErr401(messageCode);
        navigate("/");
        setPassword("");
      }
    }
  };
  return (
    <FormLogin>
      <Form
        style={{
          width: "40%",
          height: "60%",
          border: "1px solid #fff",
          borderRadius: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff",
          boxShadow: "3px 3px 3px rgba(255, 255, 255, 0.438)",
        }}
        onSubmitCapture={() => Submit()}
      >
        <FormBox style={{ border: "none" }}>
          <TitleLogin>Đăng nhập </TitleLogin>
        </FormBox>
        <FormBox>
          <span style={{ width: "10%", textAlign: "center" }}>
            <UserOutlined />
          </span>
          <InputLogin
            type="text"
            id="username"
            placeholder=" "
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
          <LabelLogin htmlFor="username">Enter your username</LabelLogin>
          <span style={{ width: "10%" }}></span>
        </FormBox>
        <FormBox>
          <span style={{ width: "10%", textAlign: "center" }}>
            <LockOutlined />
          </span>
          <InputLogin
            type={showPassword ? "text" : "password"}
            id="password"
            placeholder=" "
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <LabelLogin htmlFor="password">Enter your password</LabelLogin>
          <span
            style={{ width: "10%", textAlign: "center" }}
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </span>
        </FormBox>
        <FormBox style={{ border: "none" }}>
          <Link to={path.forgotPassword}> Forgot password ?</Link>
        </FormBox>
        <FormBox style={{ border: "none", justifyContent: "center" }}>
          <Button
            htmlType="submit"
            style={{ width: "60%", height: "100%", borderRadius: "20px" }}
          >
            Submit
          </Button>
        </FormBox>
      </Form>

      {err403 || err401 ? (
        <WarningBlock propDisplay={timeTest ? "flex" : "none"}>
          {err403 || err401}
        </WarningBlock>
      ) : null}
    </FormLogin>
  );
};

export default Login;
