import { Col, Row, Button, Form, Input } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useState } from "react";
import { apiv1 } from "../../../core/data";
import { CheckStatus } from "../../../core/status";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const Submit = async () => {
    try {
      await apiv1.post("/forgot-password", { email });
    } catch (err) {
      const statusCode = err.response.data.code;
      const messageCode = err.response.data.message;
      // CheckStatus(statusCode, messageCode);
    }
  };
  return (
    <Row span={8}>
      <Col span={8}></Col>
      <Col span={8}>
        <Form onSubmitCapture={() => Submit()} style={{}}>
          <Form.Item>
            <h1>Forgot Password</h1>
          </Form.Item>
          <Form.Item>
            <Input
              type="email"
              prefix={<MailOutlined />}
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              name="email"
              value={email}
            />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit"> Submit</Button>
          </Form.Item>
        </Form>
      </Col>
      <Col span={8}></Col>
    </Row>
  );
};
export default ForgotPassword;
