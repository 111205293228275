import BlankLayout from "../../components/layouts/BlankLayout"
import { path } from "../../routers/path"
import Login from "./page"
const router = [
    {
        path:path.login,
        page:Login,
        exact:true,
        layout: BlankLayout,
        checkLogin: false,
    } 
]
export default router