import React, { useState, useReducer, useEffect } from "react";
import { Table } from "antd";
import { apiv1 } from "../../../../core/data";
import { useDataAll } from "../../../../hooks";

const InventoryProduct = () => {
  const [dataAll, dispatchDataAll] = useDataAll();
  const [inventoryProductData, setInventoryProductData] = useState([]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    const getInventoryProduct = async () => {
      try {
        const res = await apiv1.get("/product_inventories",dataAll.keyHeader);
        console.log(res.data.metadata.data);
        setInventoryProductData(res.data.metadata.data);
      } catch (error) {
        console.log(error.message);
      }
    };

    getInventoryProduct();
  }, [reducerValue]);

  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <Table
          dataSource={inventoryProductData}
          columns={[
            {
              title: "Mã kho",
              dataIndex: "InventoryId",
              key: "InventoryId",
            },
            {
              title: "Mã sản phẩm",
              dataIndex: "ProductId",
              key: "ProductId",
            },
            {
              title: "Số lượng",
              dataIndex: "quantity",
              key: "quantity",
            },
          ]}
        ></Table>
      </div>
    </>
  );
};

export default InventoryProduct;