import clsx from "clsx";
import style from "../styles/index.module.css";
import { useDataAll } from "../../../hooks";
import { Col, Row } from "antd";
import {
  UserOutlined,
  SkinOutlined,
  UserSwitchOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { useState, useEffect } from "react";

import { apiv1 } from "../../../core/data/api";
import { CheckStatus } from "../../../core/status";

function Home() {
  const [dataAll] = useDataAll();
  const [countData, setCountData] = useState({});
// console.log(dataAll.keyHeader);
  useEffect(() => {
    const countDataDash = async () => {
      try {
        const res = await apiv1.get("/count", dataAll.keyHeader);
        setCountData(res.data.metadata.counts);
      } catch (error) {
        const statusCode = error.response.data.code
        const messageCode = error.response.data.message
        // CheckStatus(statusCode, messageCode)
      }
    };

    countDataDash();
  }, []);

  return (
    <>
      <div
        className={clsx(style.HomePage, "HomePage")}
      >
        {/* <h1>Home page {process.env.PORT}</h1>
        <p>Status : {dataAll.dataAccount.status}</p>
        {/*<p>id : {dataAll.dataAccount.metadata.user._id.toString()}</p>
        <p>accessToken : {dataAll.dataAccount.metadata.tokens.accessToken}</p>*/}
      </div>
      <div className="count" style={{ marginTop: "30px" }}>
        <Row gutter={[16,16]}>
          <Col span={6}>
            <div
              className={clsx(style.dashCount, "dashCount")}
              style={{ background: "green" }}
            >
              <div className={clsx(style.dashCounts, "dashCounts")}>
                <h3>{countData.product}</h3>
                <h4>Sản phẩm</h4>
              </div>
              <div className={clsx(style.dashImg, "dashImg")}>
                <SkinOutlined
                  style={{
                    fontSize: "54px",
                    color: "#fff",
                  }}
                />
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div
              className={clsx(style.dashCount, "dashCount")}
              style={{ background: "orange" }}
            >
              <div className={clsx(style.dashCounts, "dashCounts")}>
                <h3>{countData.customer}</h3>
                <h4>Khách hàng</h4>
              </div>
              <div className={clsx(style.dashImg, "dashImg")}>
                <UserOutlined
                  style={{
                    fontSize: "54px",
                    color: "#fff",
                  }}
                />
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div
              className={clsx(style.dashCount, "dashCount")}
              style={{ background: "yellow" }}
            >
              <div className={clsx(style.dashCounts, "dashCounts")}>
                <h3>{countData.supplier}</h3>
                <h4>Nhà cung cấp</h4>
              </div>
              <div className={clsx(style.dashImg, "dashImg")}>
                <UserSwitchOutlined
                  style={{
                    fontSize: "54px",
                    color: "#fff",
                  }}
                />
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className={clsx(style.dashCount, "dashCount")}>
              <div className={clsx(style.dashCounts, "dashCounts")}>
                <h3>{countData.inventory}</h3>
                <h4>Kho hàng</h4>
              </div>
              <div className={clsx(style.dashImg, "dashImg")}>
                <ShopOutlined
                  style={{
                    fontSize: "54px",
                    color: "#fff",
                  }}
                />
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className={clsx(style.dashCount, "dashCount")} style={{"background":"blue"}}>
              <div className={clsx(style.dashCounts, "dashCounts")}>
                <h3>{countData.order}</h3>
                <h4>Đơn hàng</h4>
              </div>
              <div className={clsx(style.dashImg, "dashImg")}>
                <ShopOutlined
                  style={{
                    fontSize: "54px",
                    color: "#fff",
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default Home;
