import { IMPORT_ORDER_QUANTITY } from "./action";
import { IMPORT_ORDER_INVENTORY } from "./action";
import { IMPORT_ORDER_PRODUCT } from "./action";
import {
  IMPORT_CUSTOMER,
  IMPORT_ORDER_DETAIL,
  IMPORT_ORDER_NAME,
  IMPORT_ORDER_STATUS,
} from "./action";
const setImportOrderProduct = (value) => {
  return {
    type: IMPORT_ORDER_PRODUCT,
    value: value,
  };
};
const setImportOrderQuantity = (value) => {
  return {
    type: IMPORT_ORDER_QUANTITY,
    value: value,
  };
};
const setImportOrderInventory = (value) => {
  return {
    type: IMPORT_ORDER_INVENTORY,
    value: value,
  };
};
const setImportOrderName = (value) => {
  return {
    type: IMPORT_ORDER_NAME,
    value: value,
  };
};
const setImportOrderStatus = (value) => {
  return {
    type: IMPORT_ORDER_STATUS,
    value: value,
  };
};
const setImportOrderDetail = (value) => {
  return {
    type: IMPORT_ORDER_DETAIL,
    value: value,
  };
};
const setImportCustomer = (value) => {
  return {
    type: IMPORT_CUSTOMER,
    value: value,
  };
};
export {
  setImportCustomer,
  setImportOrderName,
  setImportOrderStatus,
  setImportOrderDetail,
  setImportOrderProduct,
  setImportOrderInventory,
  setImportOrderQuantity,
};
