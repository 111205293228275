import React, { useState } from "react";
import { Input, Button, Form } from "antd";

import { apiv1 } from "../../../core/data";
import { useDataAll } from "../../../hooks";
import { CheckStatus } from "../../../core/status";

const AddCustomer = () => {
  const [dataAll, dispatchDataAll] = useDataAll();
  const [customerNameInput, setCustomerNameInput] = useState("");
  const [customerGenderInput, setCustomerGenderInput] = useState("");
  const [customerAddressInput, setCustomerAddressInput] = useState("");
  const [customerPhoneInput, setCustomerPhoneInput] = useState(null);
  const [customerEmailInput, setCustomerEmailInput] = useState("");

  const handleSubmit = async () => {
    const dataCustomer = {
      customer_name: customerNameInput,
      customer_gender: customerGenderInput,
      customer_address: customerAddressInput,
      customer_phone: customerPhoneInput,
      customer_email: customerEmailInput,
    };
    try {
      await apiv1.post("/customers", dataCustomer, dataAll.keyHeader);
    } catch (err) {
      const statusCode = err.response.data.code;
      const messageCode = err.response.data.message;
      // CheckStatus(statusCode, messageCode);
    }
  };

  return (
    <>
      <Form layout="vertical" onSubmitCapture={handleSubmit}>
        <Form.Item label="Tên khách hàng" required>
          <Input
            placeholder="Tên khách hàng"
            name="name"
            value={customerNameInput}
            onChange={(e) => {
              setCustomerNameInput(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Giới tính">
          <Input
            placeholder="Giới tinh"
            name="gender"
            value={customerGenderInput}
            onChange={(e) => {
              setCustomerGenderInput(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Địa chỉ">
          <Input
            placeholder="Địa chỉ"
            name="address"
            value={customerAddressInput}
            onChange={(e) => {
              setCustomerAddressInput(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Số điện thoại">
          <Input
            placeholder="Số điện thoại"
            name="phone"
            value={customerPhoneInput}
            onChange={(e) => {
              setCustomerPhoneInput(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Email">
          <Input
            placeholder="Email"
            name="email"
            value={customerEmailInput}
            onChange={(e) => {
              setCustomerEmailInput(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Thêm
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddCustomer;
