import { path } from "../../routers/path"
import Profile from "./page"
import DefaultLayout from "../../components/layouts/Default"
const routers = [
    {
        path:path.profile,
        page:Profile,
        layout:DefaultLayout,
    }
]
export default routers